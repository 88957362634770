// let baseUrl = "http://localhost:5000/api";
// let mediaUrl = "http://localhost:5000";
let baseUrl = "https://nutritioncoach-api-dev.falconweb.app/api";
let mediaUrl = "https://nutritioncoach-api-dev.falconweb.app";

const appName = "reactapp";
let env = "dev";

if (window.location.host === "nutritioncoach-admin-dev.falconweb.app") {
  baseUrl = "https://nutritioncoach-api-dev.falconweb.app/api";
  mediaUrl = "https://nutritioncoach-api-dev.falconweb.app";
} else if (
  window.location.host === "nutritioncoach-admin-staging.falconweb.app"
) {
  baseUrl = "https://nutritioncoach-api-dev.falconweb.app/api";
  mediaUrl = "https://nutritioncoach-api-dev.falconweb.app";
  env = "staging";
} else if (
  window.location.host === "admin.nutritioncoachofficial.com"
) {
  baseUrl = "https://api.nutritioncoachofficial.com/api";
  mediaUrl = "https://api.nutritioncoachofficial.com";
  env = "prod";
}

export { baseUrl, mediaUrl, appName, env };
