import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Avatar, Box } from "@mui/material";
import api from "api";
import { useEffect } from "react";
import { mediaUrl } from "config";
import { toast } from "react-toastify";

const ViewStore = ({ openModalView, handleModalView, storeId }) => {
  const [stores, setstores] = useState();
  const [address, setAddress] = useState([]);

  const getUsersByid = async () => {
    const data = await api("get", `/v1/stores/${storeId}`);
    setstores(data);
    console.log("🚀 ~ getUsersByid ~ data:", data);
  };

  const getAddress = async () => {
    try {
      const response = await fetch(
        "https://bigazure.com/api/json_v3/cities/get_cities.php"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setAddress(data?.response);
    } catch (error) {
      toast.error("Error fetching address:");
    }
  };

  useEffect(() => {
    getUsersByid();
    getAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCityName = (cityCode, address) => {
    const city = address.find((item) => item.CITY_CODE === cityCode);
    return city ? city.CITY_NAME : "";
  };

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          Store
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        mt={2}
                        mb={2}
                        gap="16px"
                        sx={{
                          borderRadius: "none",
                        }}
                      >
                        {/* <Avatar
                          alt="Avatar"
                          src={
                            stores?.store?.photo
                              ? `${mediaUrl}/${stores?.store?.photo}`
                              : ""
                          }
                          sx={{
                            width: "70%",
                            height: "70%",
                            borderRadius: "10px",
                          }}
                        /> */}
                        {stores?.store?.photo ? (
                          <a
                            href={`${mediaUrl}/${stores?.store?.photo}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          >
                            <Avatar
                              alt="Avatar"
                              src={`${mediaUrl}/${stores?.store?.photo}`}
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50px",
                              }}
                            />
                          </a>
                        ) : (
                          <Avatar
                            alt="Avatar"
                            src={`${mediaUrl}/${stores?.store?.photo}`}
                            sx={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          />
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's name here"
                          type="text"
                          value={stores?.store?.name}
                          name="name"
                          maxLength={20}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Email</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's email here"
                          type="text"
                          value={stores?.store.email}
                          name="email"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Description
                        </label>
                        <Input
                          style={{
                            resize: "none",
                          }}
                          type="textarea"
                          className="form-control-alternative text-default"
                          value={stores?.store?.description}
                          name="description"
                          disabled
                          rows={10} // Adjust rows based on expanded state
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Phone no
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Phone no"
                          type="phone"
                          value={stores?.store?.phone}
                          name="phone"
                          maxLength={11}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Category
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Category"
                          type="text"
                          value={stores?.store?.category}
                          name="category"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Country
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Country"
                          type="text"
                          value={stores?.store?.country}
                          name="country"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          City
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="City"
                          type="text"
                          value={getCityName(stores?.store?.city, address)}
                          name="city"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Address
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Address"
                          type="text"
                          value={stores?.store?.address}
                          name="address"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewStore;
