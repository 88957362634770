/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Avatar, Box } from "@mui/material";
import api from "api";
import { useEffect } from "react";
import { mediaUrl } from "config";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

const ViewCoach = ({ openModalView, handleModalView, userId }) => {
  const [users, setUsers] = useState();

  const getUsersByid = async () => {
    const data = await api("get", `/v1/users/${userId}`);
    setUsers(data);
  };

  useEffect(() => {
    getUsersByid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  const downloadImage = () => {
    if (selectedImage) {
      const downloadLink = document.createElement("a");
      downloadLink.href = selectedImage;
      downloadLink.download = "zoomed_image.jpg";

      // Append the link to the body to trigger download
      document.body.appendChild(downloadLink);

      // Click the link programmatically
      downloadLink.click();

      // Clean up: Remove the link from the DOM after download
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          Coach
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        mt={2}
                        mb={2}
                        gap="16px"
                      >
                        {/* <Avatar
                          src={
                            users
                              ?.user?.photo
                              ? `${mediaUrl}/${users?.user?.photo}`
                              : ""
                          }
                          style={{
                            height: "60px",
                            width: "60px",
                          }}
                        /> */}
                        {users?.user?.photo ? (
                          <a
                            href={`${mediaUrl}/${users?.user?.photo}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          >
                            <Avatar
                              alt="Avatar"
                              src={`${mediaUrl}/${users?.user?.photo}`}
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50px",
                              }}
                            />
                          </a>
                        ) : (
                          <Avatar
                            alt="Avatar"
                            src={`${mediaUrl}/${users?.user?.photo}`}
                            sx={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          />
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="text"
                          value={users?.user?.name}
                          name="name"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label"> Email</label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="text"
                          value={users?.user?.email}
                          name="email"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Role
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          id="input-role"
                          type="text"
                          value={users?.user?.role}
                          name="role"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label"> Phone no.</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="text"
                          value={users?.user?.phone}
                          name="phone"
                          maxLength="11"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Category</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          value={users?.user?.user_data?.category.join(", ")}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Stipend</label>
                        <InputGroup
                          style={
                            {
                              // boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                            }
                          }
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                backgroundColor: "#E9ECEF",
                                padding: "0% 2px 0% 14px",
                                borderTopLeftTadius: "6px",
                                borderBottomLeftRadius: "6px",
                              }}
                            >
                              $
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            style={{
                              textTransform: "capitalize",
                            }}
                            className="form-control-alternative text-default"
                            value={users?.user?.user_data?.stipend}
                            disabled
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Field</label>
                        {/* <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          value={users?.user?.user_data?.field}
                          disabled
                        /> */}
                        <Input
                          style={{
                            resize: "none",
                          }}
                          type="textarea"
                          className="form-control-alternative text-default"
                          value={users?.user?.user_data?.field}
                          disabled
                          rows={10} // Adjust rows based on expanded state
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Experience</label>
                        <Input
                          className="form-control-alternative text-default"
                          value={users?.user?.user_data?.experience}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    {users?.user?.user_data?.education?.length ? (
                      <Col lg="6" style={{ margin: "0" }}>
                        <FormGroup
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                          }}
                        >
                          <label className="form-control-label">
                            Education
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                          >
                            {users?.user?.user_data?.education.map(
                              (item, index) => {
                                const isPdf = item.endsWith(".pdf");
                                const url = `${mediaUrl}/${item}`;

                                if (isPdf) {
                                  return (
                                    <div key={index}>
                                      <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        View PDF
                                      </a>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index}>
                                      <Avatar
                                        onClick={() => {
                                          openModal(`${mediaUrl}/${item}`);
                                        }}
                                        src={url}
                                        style={{
                                          height: "60px",
                                          width: "60px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              }
                            )}
                          </div>
                          {/* <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="text"
                          value={users?.user?.user_data?.education}
                          name="phone"
                          maxLength="11"
                          disabled
                        /> */}
                        </FormGroup>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
      >
        {selectedImage && (
          <a>
            <img
              src={selectedImage}
              alt="Zoomed Image"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                position: "relative",
              }}
            />
          </a>
        )}
        <CloseIcon
          style={{
            position: "absolute",
            right: "0",
            cursor: "pointer",
            color: "#007BFF",
          }}
          onClick={closeModal}
        />
        <DownloadIcon
          onClick={downloadImage}
          style={{
            position: "absolute",
            right: "30px",
            cursor: "pointer",
            color: "#007BFF",
          }}
        />
      </Modal>
    </>
  );
};

export default ViewCoach;
