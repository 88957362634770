// reactstrap components
import { useState, useEffect } from "react";
import api from "../../api";
import { Store, UpdateStore } from "../../StoreContext";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Card, CardHeader, Button, Media, Table, Row } from "reactstrap";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import ViewCoach from "./ViewCoach";
import { mediaUrl } from "config";
import EditCoach from "./EditCoach";

const Coach = () => {
  const { users } = Store();
  const [loading, setLoading] = useState(true);
  const updateStore = UpdateStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [switchState, setSwitchState] = useState(false);
  const [switchStateStatus, setSwitchStateStatus] = useState(false);
  const [switchStateFeatured, setSwitchStateFeatured] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [updateType, setUpdateType] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [editModal, setEditModal] = useState(false);

  const [user, setUser] = useState({
    _id: "",
    name: "",
    email: "",
    role: "",
    phone: "",
    photo: "",
    user_data: {
      field: "",
      stipend: "",
      category: [],
      experience: "",
      education: [],
    },
  });

  const handleEditModal = (item) => {
    if (item) {
      setUser({
        _id: item._id || "",
        name: item.name || "",
        email: item.email || "",
        role: item.role || "",
        phone: item.phone || "",
        photo: item.photo || "",
        user_data: {
          field: item.user_data?.field || "",
          stipend: item.user_data?.stipend || "",
          category: item.user_data?.category || [],
          experience: item.user_data?.experience || "",
          education: item.user_data?.education || [],
        },
      });
    }

    setEditModal((open) => !open);
    return editModal;
  };

  const getUsers = async () => {
    const data = await api("get", "/v1/users?role=coach");
    updateStore({ users: data.users });
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setSelectedUserId(id);
    return openModalView;
  };

  const handleDelete = (id) => {
    api("delete", `/v1/users/${id}`).then(() => {
      toast.success("Coach deleted successfully");
      getUsers();
    });
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setSwitchStateStatus(!switchStateStatus); // Revert switch state back to original
    setSwitchStateFeatured(!switchStateFeatured); // Revert switch state back to original
  };

  const handleUpdateCurrent = async () => {
    setLoadingButton(true);

    if (selectedUser) {
      try {
        const statusEndpoint = `/v1/admins/coach-status/${
          selectedUser._id
        }?status=${switchStateStatus ? "active" : "inactive"}`;

        await api("patch", statusEndpoint);
        toast.success("Current status updated successfully");
        getUsers();
        setLoadingButton(false);
      } catch (error) {
        toast.error("Failed to update current status");
        setLoadingButton(false);
      }
    }
    setIsDialogOpen(false);
  };

  const handleUpdateFeatured = async () => {
    setLoadingButton(true);

    if (selectedUser) {
      try {
        const featuredEndpoint = `/v1/admins/featured-coach/${selectedUser._id}
        `;
        await api("patch", featuredEndpoint);
        toast.success("Featured status updated successfully");
        getUsers();
        setLoadingButton(false);
      } catch (error) {
        toast.error("Failed to update featured status");
        setLoadingButton(false);
      }
    }
    setIsDialogOpen(false);
  };

  const handleSwitchToggle = (item) => {
    setSwitchStateStatus(item.user_data.status === "active" ? false : true);
    setSelectedUser(item);
    handleDialogOpen();
    setUpdateType("current");
  };

  const handleSwitchToggleFeatured = (item) => {
    // if (
    //   item.user_data.status === "inactive" &&
    //   item.user_data.featured === false
    // ) {
    //   toast.error("First activate status, then feature can be active");
    //   return;
    // }

    setSwitchStateFeatured(item.user_data.featured === true ? false : true);
    setSelectedUser(item);
    handleDialogOpen();
    setUpdateType("featured");
  };

  const handleUpdateAction = async () => {
    if (selectedUser && updateType) {
      if (updateType === "current") {
        handleUpdateCurrent();
      } else if (updateType === "featured") {
        handleUpdateFeatured();
      }
    }
  };
  // console.log("🚀 ~ Coach ~ switchStateStatus:", switchStateStatus);
  // console.log("🚀 ~ Coach ~ switchStateFeatured:", switchStateFeatured);

  return (
    <>
      <div style={{ paddingTop: "30px", padding: "0 2%" }}>
        <Row
          style={{
            // backgroundColor: "red",
            padding: "2% 0",
          }}
        >
          <div className="col">
            <Card style={{ border: "1px solid #E4E5E8" }} className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">All Coaches</h3>
                  {/* <Button
                    style={{
                      backgroundColor: "#218838",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "none",
                    }}
                    color="primary"
                    href="#pablo"
                    size="md"
                  >
                    Create
                  </Button> */}
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Image
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Is Featured
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          Coaches Not Found
                        </td>
                      </tr>
                    ) : (
                      users
                        ?.filter((item) => !item.deleted)
                        .map((item, index) => {
                          return (
                            <tr style={{ color: "black" }} key={item._id}>
                              <th scope="row">{index + 1}</th>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Avatar
                                    src={
                                      /* eslint-disable @typescript-eslint/restrict-template-expressions */ item?.photo
                                        ? `${mediaUrl}/${item?.photo}`
                                        : ""
                                    }
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    {item.name?.charAt(0).toUpperCase()}
                                  </Avatar>
                                </Media>
                              </th>
                              <td style={{ textTransform: "capitalize" }}>
                                {item.name?.length > 30
                                  ? item.name.substring(0, 30) + "..."
                                  : item.name}
                              </td>
                              <td title={item.email}>
                                {item.email?.length > 30
                                  ? item.email.substring(0, 30) + "..."
                                  : item.email}
                              </td>
                              <td className="text-capitalize">
                                Current Status:{" "}
                                <span
                                  style={{
                                    color:
                                      item?.user_data?.status === "active"
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {item?.user_data?.status === "active"
                                    ? "Active"
                                    : "Disabled"}
                                </span>
                                <br />
                                <Switch
                                  checked={item?.user_data?.status === "active"}
                                  onChange={() => handleSwitchToggle(item)}
                                  color="success"
                                />
                              </td>
                              <td className="text-capitalize">
                                Current Status:{" "}
                                <span
                                  style={{
                                    color:
                                      item?.user_data?.featured === true
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {item?.user_data?.featured === true
                                    ? "Active"
                                    : "Disabled"}
                                </span>
                                <br />
                                <Switch
                                  checked={item?.user_data?.featured === true}
                                  onChange={() =>
                                    handleSwitchToggleFeatured(item)
                                  }
                                  color="success"
                                />
                              </td>
                              <td
                                className="text-left"
                                style={{ display: "flex", gap: "6px" }}
                              >
                                <VisibilityIcon
                                  sx={{
                                    color: "#007BFF",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleModalView(item._id)}
                                />
                                <EditCalendarIcon
                                  sx={{
                                    color: "#007BFF",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleEditModal(item)}
                                />
                                <DeleteIcon
                                  sx={{
                                    color: "#007BFF",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDelete(item._id)}
                                />
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>
      </div>

      {/* Dialog for switch on/off */}
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            width: "500px",
            borderRadius: "10px",
            padding: "2% 0",
          },
        }}
      >
        <Box
          sx={{
            padding: "6% 10%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "35px",
          }}
        >
          <Typography>
            <CancelOutlinedIcon sx={{ fontSize: "7rem", color: "#f15e5e" }} />
          </Typography>
          <Typography
            fontSize="2rem"
            fontWeight="medium"
            fontFamily="arial"
            color="#636363"
          >
            Are you sure?
          </Typography>
          <Typography textAlign="center" fontSize="1rem" color="#999">
            Do you really want to update these records? This process cannot be
            undone.
          </Typography>
          <Box>
            <Button
              onClick={handleCancel}
              style={{ backgroundColor: "#c1c1c1", width: "120px" }}
              startIcon={<CancelOutlinedIcon />}
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateAction}
              style={{ backgroundColor: "#f15e5e", width: "120px" }}
            >
              {loadingButton ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Update"
              )}
            </Button>
          </Box>
        </Box>
      </Dialog>
      {openModalView && (
        <ViewCoach
          openModalView={openModalView}
          handleModalView={handleModalView}
          userId={selectedUserId}
        />
      )}
      {editModal && (
        <EditCoach
          openModal={editModal}
          handleModal={handleEditModal}
          user={user}
          setUser={setUser}
          getUsers={getUsers}
        />
      )}
    </>
  );
};

export default Coach;
