/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Avatar, Box, CircularProgress } from "@mui/material";
import { useRef } from "react";
import uploadIcon from "../../assets/uploadIcon.svg";
import { mediaUrl } from "config";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const names = [
  "Fitness Expert",
  "Muscle Strengthening",
  "Yoga Expert",
  "Nutrition Consultant",
  "Weight Loss",
  "Weight Gain",
  "Dermatologist",
  "Others",
];

const EditCoach = ({ openModal, handleModal, user, setUser, getUsers }) => {
  const fileInputRef = useRef();
  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    role: user.role,
    phone: user.phone,
    photo: user.photo,
    user_data: {
      field: user?.user_data?.field,
      stipend: user?.user_data?.stipend,
      experience: user?.user_data?.experience,
    },
  });

  // const handleChangeSelect = (event) => {
  //   const selectedValues = event.target.value;
  //   const selectedIndices = selectedValues.map((category) =>
  //     names.indexOf(category)
  //   );
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     user_data: {
  //       ...prevState.user_data,
  //       category: selectedIndices,
  //     },
  //   }));
  // };

  const [loadingButton, setLoadingButton] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "stipend") {
      if (/^\d*\.?\d*$/.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          user_data: {
            ...prevData.user_data,
            [name]: value,
          },
        }));
      } else {
        // toast.error("Invalid stipend value. Only numbers are allowed.");
      }
    } else if (name in formData.user_data) {
      setFormData((prevData) => ({
        ...prevData,
        user_data: {
          ...prevData.user_data,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Image size must be less than 5MB.");
        return;
      }
      console.log("file", file);
      // const imageUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        photo: file,
      }));
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      photo: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingButton(true);

    if (formData.name === "") {
      return toast.error("Please enter customer's name");
    }

    const formSubmitData = new FormData();

    formSubmitData.append("name", formData?.name);
    formSubmitData.append("email", formData?.email);
    formSubmitData.append("phone", formData?.phone);
    formSubmitData.append("role", formData?.role);
    formSubmitData.append("user_data.field", formData?.user_data.field);
    formSubmitData.append(
      "user_data.experience",
      formData?.user_data.experience
    );
    const stipendValue = formData?.user_data.stipend;

    const numberRegex = /^\d+(\.\d+)?$/;

    if (numberRegex.test(stipendValue)) {
      formSubmitData.append("user_data.stipend", stipendValue);
    } else {
      toast.error("Invalid stipend value. Only numbers are allowed.");
      setLoadingButton(false);
      return;
    }
    // formSubmitData.append(
    //   "user_data.category",
    //   JSON.stringify(formData?.user_data?.category)
    // );

    if (formData.photo) {
      formSubmitData.append("photo", formData.photo);
    } else if (formData.photo === null) {
      formSubmitData.append("photo", "");
    } else if (formData.photo === undefined) {
      formSubmitData.append("photo", "");
    }
    try {
      const response = await api(
        "put",
        `/v1/users/${user?._id}`,
        formSubmitData,
        {}
      );
      toast.success("Coach updated successfully");
      getUsers();
      handleModal();
    } catch (error) {
      setLoadingButton(false);
      console.error("Error Occurred");
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit a Coach
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        alignItems="center"
                        mt={2}
                        mb={2}
                        gap="18px"
                      >
                        <Avatar
                          src={
                            formData.photo
                              ? typeof formData.photo === "string"
                                ? `${mediaUrl}/${formData.photo}`
                                : URL.createObjectURL(formData.photo)
                              : ""
                          }
                          style={{ width: "100px", height: "100px" }}
                        />
                        {formData.photo ? (
                          <Box margin="0" display="flex" alignItems="center">
                            <Button
                              onClick={handleRemove}
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                textTransform: "capitalize",
                                cursor: "pointer",
                                color: "#28A745",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        ) : (
                          <Box
                            borderRadius={"12px"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                accept="image/jpeg, image/png"
                              />
                              <img
                                src={uploadIcon}
                                alt="Upload Image"
                                style={{ cursor: "pointer" }}
                                onClick={handleImageClick}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's name here"
                          type="text"
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          maxLength={20}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Email</label>
                        <Input
                          disabled
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's email here"
                          type="text"
                          value={formData.email}
                          name="email"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Role
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          disabled
                          className="form-control-alternative text-default"
                          placeholder="Role"
                          type="role"
                          value={formData.role}
                          name="role"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Phone no
                        </label>
                        <Input
                          disabled
                          className="form-control-alternative text-default"
                          placeholder="Phone no"
                          type="phone"
                          value={formData.phone}
                          name="phone"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Field</label>
                        {/* <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          value={formData?.user_data?.field}
                          placeholder="Field"
                          type="text"
                          name="field"
                          onChange={handleChange}
                        /> */}
                        <Input
                          style={{
                            resize: "none",
                          }}
                          type="textarea"
                          className="form-control-alternative text-default"
                          value={formData?.user_data?.field}
                          placeholder="Field"
                          name="field"
                          onChange={handleChange}
                          rows={10} // Adjust rows based on expanded state
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="SelectMUiRow">
                    {/* <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Category</label>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={formData.user_data.category.map(
                              (index) => names[index]
                            )} // Display the actual names
                            onChange={handleChangeSelect}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250,
                                },
                              },
                            }}
                            name="category"
                          >
                            {names.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={formData.user_data.category.includes(
                                    names.indexOf(name)
                                  )}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Col> */}
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Experience</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          value={formData?.user_data?.experience}
                          placeholder="Experience"
                          type="text"
                          name="experience"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Stipend</label>
                        <InputGroup
                          style={
                            {
                              // boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                            }
                          }
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                padding: "0% 2px 0% 14px",
                                borderTopLeftTadius: "6px",
                                borderBottomLeftRadius: "6px",
                              }}
                            >
                              $
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            style={{
                              textTransform: "capitalize",
                            }}
                            className="form-control-alternative text-default"
                            value={formData?.user_data?.stipend}
                            placeholder="Stipend"
                            type="text"
                            name="stipend"
                            onChange={handleChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loadingButton}
            color="primary"
            onClick={handleSubmit}
          >
            {loadingButton ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Update"
            )}{" "}
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditCoach;
