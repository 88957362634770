// reactstrap components
import { useState, useEffect } from "react";
import api from "../../api";
import { Card, CardHeader, Table, Row, Input } from "reactstrap";

// core components
// import Header from "components/Headers/Header.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Box, Pagination } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import LinearProgress from "@mui/material/LinearProgress";
import ViewSessions from "./ViewSessions";
import SearchIcon from "@mui/icons-material/Search";

const Sessions = () => {
  const [loading, setLoading] = useState(true);
  const [openModalView, setOpenModalView] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [sessions, setSessions] = useState();
  const [searchName, setSearchName] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);

  const getUsers = async () => {
    const data = await api("get", "/v1/slots");
    setSessions(data?.slots);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSlotsSearch = async () => {
    if (searchName) {
      const data = await api("get", `/v1/slots?name=${searchName}`);
      setSessions(data?.slots || []);
      setSearchPerformed(true);
    } else {
      getUsers(); // If searchName is empty, show all data
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSlotsSearch();
    }
  };

  const handleDelete = (id) => {
    api("delete", `/v1/blogs/${id}`).then(() => {
      toast.success("Session deleted successfully");
      getUsers();
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Ensure classes is defined and default to an empty array if it's not
  const data = sessions || [];

  // Calculate the number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Slice data for the current page
  const currentData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setSelectedSessionId(id);
    return openModalView;
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "2-digit", year: "numeric" };
    const parts = date.toLocaleDateString("en-US", options).split(" ");

    const month = parts[0];
    const day = parts[1].replace(",", "");
    const year = parts[2];

    return `${month}-${day}-${year}`;
  };

  return (
    <>
      <div
        style={{
          paddingTop: "30px",
          padding: "0 2%",
        }}
      >
        <Row
          style={{
            padding: "2% 0",
          }}
        >
          <div
            className="col"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card
              style={{
                border: "1px solid #E4E5E8",
              }}
              className="shadow"
            >
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">All Classes</h3>
                  <div
                    className="form-control-alternative text-default"
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: "1%",
                      borderRadius: "6px",
                    }}
                  >
                    <Input
                      style={{
                        border: "none",
                      }}
                      placeholder="Search by name here"
                      type="text"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      onKeyDown={handleKeyPress}
                      maxLength={20}
                    />
                    <SearchIcon
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={getSlotsSearch}
                    />
                  </div>
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Customer Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Coach Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Start Time
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        End Time
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Date
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          Sessions Not Found
                        </td>
                      </tr>
                    ) : (
                      currentData.map((item, index) => (
                        <tr key={item._id} style={{ color: "black" }}>
                          <th scope="row">
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </th>
                          <td style={{ textTransform: "capitalize" }}>
                            {item?.customer?.name?.length > 30
                              ? item.customer.name.substring(0, 30) + "..."
                              : item.customer?.name || "NA"}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {item?.coach?.name?.length > 30
                              ? item.coach.name.substring(0, 30) + "..."
                              : item.coach?.name || "NA"}
                          </td>
                          <td>{item.startTime.split("T")[1].split(".")[0]}</td>
                          <td>{item.endTime.split("T")[1].split(".")[0]}</td>
                          <td className="text-capitalize">
                            {formatDate(item.createdAt.split("T")[0])}
                          </td>
                          <td
                            className="text-left"
                            style={{
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                color: "#007BFF",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleModalView(item._id)}
                            />
                            <DeleteIcon
                              sx={{
                                color: "#007BFF",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(item._id)}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mb={4}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{ marginTop: "20px" }}
          />
        </Box>
        {openModalView && (
          <ViewSessions
            openModalView={openModalView}
            handleModalView={handleModalView}
            selectedSessionId={selectedSessionId}
          />
        )}
      </div>
    </>
  );
};

export default Sessions;
