import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import api from "api";
import { useEffect } from "react";

const ViewBookings = ({
  openModalView,
  handleModalView,
  selectedBookingId,
}) => {
  const [bookingData, setBookingData] = useState();

  const getBookingByid = async () => {
    const data = await api("get", `/v1/bookings/${selectedBookingId}`);
    setBookingData(data?.booking);
  };

  useEffect(() => {
    getBookingByid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          Booking
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Channel Name
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="channel name here"
                          type="text"
                          value={bookingData?.channelName}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Status</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="status here"
                          type="text"
                          value={bookingData?.status}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Customer Name
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="customer name here"
                          type="text"
                          value={bookingData?.customer?.name}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Customer Corcern
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="customer concern here"
                          type="text"
                          value={bookingData?.customer?.user_data?.concern}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Coach Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="coach name here"
                          type="text"
                          value={bookingData?.coach?.name}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Coach Stipend
                        </label>
                        <InputGroup
                          style={
                            {
                              // boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                            }
                          }
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                backgroundColor: "#E9ECEF",
                                padding: "0 2px 0 14px",
                                borderTopLeftTadius: "6px",
                                borderBottomLeftRadius: "6px",
                              }}
                            >
                              $
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="stipend here"
                            type="text"
                            value={bookingData?.coach?.user_data?.stipend}
                            disabled
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Slot Start Time
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter user's email here"
                          type="text"
                          value={
                            bookingData?.slot?.startTime
                              .split("T")[1]
                              .split(".")[0]
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Slot End Time
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter user's email here"
                          type="text"
                          value={
                            bookingData?.slot?.endTime
                              .split("T")[1]
                              .split(".")[0]
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">UID</label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="uid here"
                          type="text"
                          value={bookingData?.uid}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewBookings;
