import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupText,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import api from "api";
import { useEffect } from "react";
import { toast } from "react-toastify";

const ViewOrders = ({ openModalView, handleModalView, orderId }) => {
  const [orders, setOrders] = useState();
  const [address, setAddress] = useState([]);

  const getOrders = async () => {
    const data = await api("get", `/v1/orders/${orderId}`);
    setOrders(data.order);
  };

  console.log("🚀 ~ ViewOrders ~ orders:", orders);

  const getAddress = async () => {
    try {
      const response = await fetch(
        "https://bigazure.com/api/json_v3/cities/get_cities.php"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setAddress(data?.response);
    } catch (error) {
      toast.error("Error fetching address:");
    }
  };

  useEffect(() => {
    getOrders();
    getAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCityName = (cityCode, address) => {
    const city = address.find((item) => item.CITY_CODE === cityCode);
    return city ? city.CITY_NAME : "";
  };

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          Order
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Customer Name
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.customer?.name}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Store Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.store?.name}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Currency</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.currency}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Total Price
                        </label>
                        <InputGroup
                          style={
                            {
                              // boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                            }
                          }
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                backgroundColor: "#E9ECEF",
                                padding: "0% 2px 0% 14px",
                                borderTopLeftTadius: "6px",
                                borderBottomLeftRadius: "6px",
                              }}
                            >
                              $
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            value={orders?.totalPrice}
                            disabled
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Receiver Name
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.delivery_data?.name}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Receiver Phone
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.delivery_data?.phone}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Status</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.status}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Products Name
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.products
                            ?.map((item) => item._id?.name)
                            .join(", ")}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Products Quantity
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.products
                            ?.map((item) => item.quantity)
                            .join(", ")}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Delivery Address
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          type="text"
                          value={orders?.delivery_data?.address}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          Delivery City
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          type="text"
                          value={getCityName(
                            orders?.delivery_data?.city,
                            address
                          )}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewOrders;
