import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Avatar, Box } from "@mui/material";
import api from "api";
import { useEffect } from "react";
import { mediaUrl } from "config";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";

const ViewBlogs = ({ openModalView, handleModalView, BlogId }) => {
  const [blogs, setBlogs] = useState();

  const [videoUrl, setVideoUrl] = useState("");
  const [showModal, setShowModal] = useState(false);

  const getBlogByid = async () => {
    const data = await api("get", `/v1/blogs/${BlogId}`);
    setBlogs(data.blog);
  };

  useEffect(() => {
    getBlogByid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLinkClick = (link) => {
    setVideoUrl(link);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setVideoUrl("");
    setShowModal(false);
  };

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          Blog
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        mt={2}
                        mb={2}
                        gap="16px"
                      >
                        {/* <Avatar
                          alt="Avatar"
                          src={
                            blogs?.photo ? `${mediaUrl}/${blogs?.photo}` : ""
                          }
                          sx={{ width: "64px", height: "64px" }}
                        /> */}
                        {blogs?.photo ? (
                          <a
                            href={`${mediaUrl}/${blogs?.photo}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          >
                            <Avatar
                              alt="Avatar"
                              src={`${mediaUrl}/${blogs?.photo}`}
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50px",
                              }}
                            />
                          </a>
                        ) : (
                          <Avatar
                            alt="Avatar"
                            src={`${mediaUrl}/${blogs?.photo}`}
                            sx={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          />
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Title</label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter user's name here"
                          type="text"
                          value={blogs?.title}
                          name="title"
                          maxLength={20}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Coach</label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter user's email here"
                          type="text"
                          value={
                            blogs?.coach?.name ? blogs?.coach?.name : "NULL"
                          }
                          name="coach"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Detail
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Description"
                          type="text"
                          value={blogs?.detail}
                          name="detail"
                          disabled
                        />
                        <Input
                          style={{
                            resize: "none",
                          }}
                          type="textarea"
                          className="form-control-alternative text-default"
                          value={blogs?.detail}
                          name="detail"
                          disabled
                          rows={10} // Adjust rows based on expanded state
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Link</label>
                        <Input
                          className="form-control-alternative text-default"
                          type="text"
                          value={blogs?.link}
                          name="coach"
                          onClick={() => handleLinkClick(blogs?.link)}
                          disabled
                        />
                        {blogs?.link && (
                          <Button
                            style={{ cursor: "pointer", float: "right" }}
                            className="form-control-alternative text-default"
                            onClick={() => handleLinkClick(blogs?.link)}
                          >
                            Play Video
                          </Button>
                        )}

                        {showModal && (
                          <div
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "50%", // Adjust width as needed
                              height: "50%", // Adjust height as needed
                              zIndex: 999, // Example z-index
                            }}
                            isOpen={showModal}
                            toggle={handleModalView}
                          >
                            <ModalBody
                              style={{ position: "relative", padding: 0 }}
                            >
                              <ReactPlayer
                                url={videoUrl}
                                controls
                                width="100%"
                                height="400px"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                }}
                              />
                            </ModalBody>
                            <ModalFooter style={{ textAlign: "right" }}>
                              <div
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: "-25px",
                                  top: "-25px",
                                }}
                                onClick={handleCloseModal}
                              >
                                <CloseIcon />
                              </div>
                            </ModalFooter>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewBlogs;
