// reactstrap components
import { useState, useEffect } from "react";
import api from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card, CardHeader, Button, Media, Table, Row } from "reactstrap";

// core components
// import Header from "components/Headers/Header.js";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import {
  Avatar,
  Box,
  Dialog,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import LinearProgress from "@mui/material/LinearProgress";
import { mediaUrl } from "config";
import AddBlogs from "./AddBlogs";
import ViewBlogs from "./ViewBlogs";
import EditBlogs from "./EditBlogs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Blogs = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [editModal, setEditModal] = useState(false);
  //   const { users } = Store();
  const [loading, setLoading] = useState(true);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [blogs, setBlogs] = useState();
  // const history = useHistory();
  const [editblog, setEditBlog] = useState({
    _id: "",
    title: "",
    detail: "",
    coach: "",
    photo: "",
    link: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const getUsers = async () => {
    const data = await api("get", "/v1/blogs");
    setBlogs(data.blogs);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id) => {
    api("delete", `/v1/blogs/${id}`).then(() => {
      toast.success("Blog deleted successfully");
      getUsers();
    });
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setSelectedBlogId(id);
    return openModalView;
  };

  const handleEditModal = (item) => {
    if (item) {
      setEditBlog({
        _id: item._id,
        title: item.title,
        detail: item.detail,
        coach: item.coach,
        photo: item.photo,
        link: item.link,
      });
    }

    setEditModal((open) => !open);
    return editModal;
  };

  // const handleBlogStatus = async (item) => {
  //   setIsDialogOpen(true);
  // };

  const handleMenuItemClick = (status, item) => {
    setSelectedBlog(item);
    setSelectedStatus(status);
    handleUpdateStatus(status, item._id);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleClick = (event, item) => {
    setSelectedBlog(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateStatus = async (newStatus, id) => {
    try {
      await api("patch", `/v1/admins/blog-status/${id}?status=${newStatus}`);
      toast.success("Blog status updated successfully");
      getUsers();
    } catch (error) {
      console.log(error, "err");
      // toast.error("Failed to update status");
    }
    handleClose();
  };

  return (
    <>
      <div
        style={{
          paddingTop: "30px",
          padding: "0 2%",
        }}
      >
        <Row
          style={{
            padding: "2% 0",
          }}
        >
          <div
            className="col"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card
              style={{
                border: "1px solid #E4E5E8",
              }}
              className="shadow"
            >
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">All Blogs</h3>
                  <Button
                    style={{
                      backgroundColor: "#218838",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "none",
                    }}
                    color="primary"
                    size="md"
                    onClick={handleModal}
                  >
                    Create Blog
                  </Button>
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Image
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Coach Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Title
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Description
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Status
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {blogs.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          Blogs Not Found
                        </td>
                      </tr>
                    ) : (
                      blogs.map((item, index) => {
                        return (
                          <tr
                            style={{
                              color: "black",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media className="align-items-center">
                                  <Avatar
                                    src={
                                      item?.photo
                                        ? `${mediaUrl}/${item?.photo}`
                                        : ""
                                    }
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    {item.title.split("")[0].toUpperCase()}
                                  </Avatar>
                                </Media>
                              </Media>
                            </th>
                            <td
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.coach?.name?.length > 30
                                ? item?.coach?.name?.substring(0, 30) + "..."
                                : item?.coach?.name
                                ? item?.coach?.name
                                : "NA"}
                            </td>
                            <td>
                              {item.title?.length > 30
                                ? item.title?.substring(0, 30) + "..."
                                : item.title}
                            </td>
                            <td title={item.detail}>
                              {item.detail?.length > 30
                                ? item.detail?.substring(0, 30) + "..."
                                : item.detail}
                            </td>
                            <td className="text-capitalize">
                              Current Status :{" "}
                              <span
                                style={{
                                  color:
                                    item.status === "pending"
                                      ? "blue"
                                      : item.status === "approved"
                                      ? "green"
                                      : item.status === "disapproved"
                                      ? "red"
                                      : "",
                                }}
                              >
                                {item.status === "pending"
                                  ? "Pending"
                                  : item.status === "approved"
                                  ? "Approved"
                                  : item.status === "disapproved"
                                  ? "Disable"
                                  : ""}
                              </span>
                              <ArrowDropDownIcon
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(event) => handleClick(event, item)}
                              />
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                PaperProps={{
                                  style: {
                                    boxShadow: "none", // This removes the box shadow
                                    border: "2px solid #EAECF0",
                                    borderRadius: "8px",
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(
                                      "approved",
                                      selectedBlog
                                    )
                                  }
                                >
                                  <ListItemText>Approved</ListItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(
                                      "disapproved",
                                      selectedBlog
                                    )
                                  }
                                >
                                  <ListItemText>Disapproved</ListItemText>
                                </MenuItem>
                              </Menu>
                            </td>
                            <td
                              className="text-left"
                              style={{
                                display: "flex",
                                gap: "6px",
                              }}
                            >
                              <VisibilityIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleModalView(item._id)}
                              />
                              <EditCalendarIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditModal(item)}
                              />
                              <DeleteIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDelete(item._id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>
        {/* Dialog for switch on/off */}
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
              width: "500px",
              borderRadius: "10px",
              padding: "2% 0",
            },
          }}
        >
          <Box
            sx={{
              padding: "6% 10%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "35px",
            }}
          >
            <Typography>
              <CancelOutlinedIcon sx={{ fontSize: "7rem", color: "#f15e5e" }} />
            </Typography>
            <Typography
              fontSize="2rem"
              fontWeight="medium"
              fontFamily="arial"
              color="#636363"
            >
              Are you sure?
            </Typography>
            <Typography textAlign="center" fontSize="1rem" color="#999">
              Do you really want to update these records? This process cannot be
              undone.
            </Typography>
            <Box>
              <Button
                onClick={handleCloseDialog}
                style={{ backgroundColor: "#c1c1c1", width: "120px" }}
                startIcon={<CancelOutlinedIcon />}
              >
                Cancel
              </Button>
              <Button
                onClick={handleUpdateStatus}
                style={{ backgroundColor: "#f15e5e", width: "120px" }}
              >
                {loadingButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
        {openModal && (
          <AddBlogs
            openModal={openModal}
            handleModal={handleModal}
            getUsers={getUsers}
          />
        )}
        {editModal && (
          <EditBlogs
            openModal={editModal}
            handleModal={handleEditModal}
            editblog={editblog}
            setEditBlog={setEditBlog}
            getUsers={getUsers}
          />
        )}
        {openModalView && (
          <ViewBlogs
            openModalView={openModalView}
            handleModalView={handleModalView}
            BlogId={selectedBlogId}
          />
        )}
      </div>
    </>
  );
};

export default Blogs;
