/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Avatar, Box, CircularProgress } from "@mui/material";
import { useRef } from "react";
import uploadIcon from "../../assets/uploadIcon.svg";
import { mediaUrl } from "config";

const EditUser = ({ openModal, handleModal, user, setUser, getUsers }) => {
  const fileInputRef = useRef();
  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    role: user.role,
    phone: user.phone,
    photo: user.photo,
    height: user.user_data.height,
    weight: user.user_data.weight,
    country: user.user_data.country,
    city: user.user_data.city,
    gender: user.user_data.gender,
    activity: user.user_data.activity,
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (
      (name === "height" || name === "weight") &&
      !/^\d*\.?\d*$/.test(value)
    ) {
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Image size must be less than 5MB.");
        return;
      }
      console.log("file", file);
      // const imageUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        photo: file,
      }));
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      photo: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingButton(true);

    if (formData.name === "") {
      return toast.error("Please enter customer's name");
    }

    const numberRegex = /^[0-9]+(\.[0-9]+)?$/;

    if (!numberRegex.test(formData.height)) {
      return toast.error("Please enter a valid height (numbers only)");
    }

    if (!numberRegex.test(formData.weight)) {
      return toast.error("Please enter a valid weight (numbers only)");
    }

    const formSubmitData = new FormData();

    formSubmitData.append("name", formData.name);
    formSubmitData.append("email", formData.email);
    formSubmitData.append("phone", formData.phone);
    formSubmitData.append("role", formData.role);
    formSubmitData.append("user_data.height", formData.height);
    formSubmitData.append("user_data.weight", formData.weight);
    formSubmitData.append("user_data.country", formData.country);
    formSubmitData.append("user_data.city", formData.city);
    formSubmitData.append("user_data.gender", formData.gender);
    formSubmitData.append("user_data.activity", formData.activity);

    if (formData.photo) {
      formSubmitData.append("photo", formData.photo);
    } else if (formData.photo === null) {
      formSubmitData.append("photo", "");
    } else if (formData.photo === undefined) {
      formSubmitData.append("photo", "");
    }
    try {
      const response = await api(
        "put",
        `/v1/users/${user?._id}`,
        formSubmitData,
        {}
      );
      toast.success("Customer updated successfully");
      getUsers();
      handleModal();
    } catch (error) {
      console.error("Error Occurred");
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit a Customer
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        alignItems="center"
                        mt={2}
                        mb={2}
                        gap="18px"
                      >
                        <Avatar
                          src={
                            formData.photo
                              ? typeof formData.photo === "string"
                                ? `${mediaUrl}/${formData.photo}`
                                : URL.createObjectURL(formData.photo)
                              : ""
                          }
                          style={{ width: "100px", height: "100px" }}
                        />
                        {formData.photo ? (
                          <Box margin="0" display="flex" alignItems="center">
                            <Button
                              onClick={handleRemove}
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                textTransform: "capitalize",
                                cursor: "pointer",
                                color: "#28A745",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        ) : (
                          <Box
                            borderRadius={"12px"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                accept="image/jpeg, image/png"
                              />
                              <img
                                src={uploadIcon}
                                alt="Upload Image"
                                style={{ cursor: "pointer" }}
                                onClick={handleImageClick}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's name here"
                          type="text"
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          maxLength={20}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Email</label>
                        <Input
                          disabled
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's email here"
                          type="text"
                          value={formData.email}
                          name="email"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Role
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          disabled
                          className="form-control-alternative text-default"
                          placeholder="Role"
                          type="role"
                          value={formData.role}
                          name="role"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Phone no
                        </label>
                        <Input
                          disabled
                          className="form-control-alternative text-default"
                          placeholder="Phone no"
                          type="phone"
                          value={formData.phone}
                          name="phone"
                          onChange={handleChange}
                          maxLength={11}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Height
                        </label>
                        <InputGroup>
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="Height"
                            type="text"
                            value={formData.height}
                            name="height"
                            onChange={handleChange}
                            maxLength={10}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                              }}
                            >
                              feet
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Weight
                        </label>
                        <InputGroup>
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="Weight"
                            type="text"
                            value={formData.weight}
                            name="weight"
                            onChange={handleChange}
                            maxLength={10}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                              }}
                            >
                              pound
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Country
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="Country"
                          type="text"
                          value={formData.country}
                          name="country"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          City
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="City"
                          type="text"
                          value={formData.city}
                          name="city"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Gender
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          type="select"
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Activity
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="Activity"
                          type="text"
                          value={formData.activity}
                          name="activity"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loadingButton}
            color="primary"
            onClick={handleSubmit}
          >
            {loadingButton ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Update"
            )}
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditUser;
