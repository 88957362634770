import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Avatar, Box } from "@mui/material";
import api from "api";
import { useEffect } from "react";
import { mediaUrl } from "config";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";

const ViewSessions = ({
  openModalView,
  handleModalView,
  selectedSessionId,
}) => {
  const [sessionData, setSessionData] = useState();

  const getSessionByid = async () => {
    const data = await api("get", `/v1/slots/${selectedSessionId}`);
    setSessionData(data?.slot);
  };

  useEffect(() => {
    getSessionByid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "2-digit", year: "numeric" };
    const parts = date.toLocaleDateString("en-US", options).split(" ");

    const month = parts[0];
    const day = parts[1].replace(",", "");
    const year = parts[2];

    return `${month}-${day}-${year}`;
  };

  console.log("🚀 ~ sessionData:", sessionData);

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          Session
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Coach Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter user's name here"
                          type="text"
                          value={sessionData?.coach?.name}
                          name="title"
                          maxLength={20}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Customer Name
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Description"
                          type="text"
                          value={sessionData?.customer?.name}
                          name="detail"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Start Time</label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Enter user's name here"
                          type="text"
                          value={
                            sessionData?.startTime.split("T")[1].split(".")[0]
                          }
                          name="title"
                          maxLength={20}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          End Time
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Description"
                          type="text"
                          value={
                            sessionData?.endTime.split("T")[1].split(".")[0]
                          }
                          name="detail"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Fee</label>
                        <InputGroup style={{}}>
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                backgroundColor: "#E9ECEF",
                                padding: "0% 2px 0% 14px",
                                borderTopLeftTadius: "6px",
                                borderBottomLeftRadius: "6px",
                              }}
                            >
                              $
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="Enter user's name here"
                            type="text"
                            value={sessionData?.coach?.user_data?.stipend}
                            name="title"
                            maxLength={20}
                            disabled
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Status
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="Description"
                          type="text"
                          value={sessionData?.status}
                          name="detail"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Date
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Description"
                          type="text"
                          value={formatDate(sessionData?.createdAt)}
                          name="date"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewSessions;
