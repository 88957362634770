/* eslint-disable react/jsx-filename-extension */
import Index from "views/Index";
import Users from "views/users/Users";
import Blogs from "views/blog/Blogs";
import Stores from "views/stores/Stores";
import Products from "views/products/Products";
import Orders from "views/orders/Orders";
import Sessions from "views/sessions/Sessions";
import Bookings from "views/booking/Bookings";
import FAQs from "views/faqs/FAQ";
import Coaches from "views/coach/Coach";
import Register from "views/Register";
import Login from "views/Login";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonIcon from "@mui/icons-material/Person";
import LaptopIcon from "@mui/icons-material/Laptop";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import ViewListIcon from "@mui/icons-material/ViewList";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
// import PaymentIcon from "@mui/icons-material/Payment";
// import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LogoutIcon from "@mui/icons-material/Logout";
import BeenhereIcon from "@mui/icons-material/Beenhere";

const handleLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("name");
  localStorage.removeItem("role");
  window.location.href = "/login";
};

const userRole = localStorage.getItem("role");
let routes = [];

if (userRole === "admin") {
  routes = [
    {
      path: "/index",
      name: "Dashboard",
      icon: <LaptopIcon />,
      component: Index,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/customers",
      name: "Customers",
      icon: <PersonIcon />,
      component: Users,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/coach",
      name: "Coaches",
      icon: <SupervisorAccountIcon />,
      component: Coaches,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/store",
      name: "Stores",
      icon: <LocalGroceryStoreIcon />,
      component: Stores,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/sessions",
      name: "Sessions",
      icon: <ViewListIcon />,
      component: Sessions,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/bookings",
      name: "Bookings",
      icon: <BeenhereIcon />,
      component: Bookings,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/products",
      name: "Products",
      icon: <CardGiftcardIcon />,
      component: Products,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/orders",
      name: "Orders",
      icon: <CardGiftcardIcon />,
      component: Orders,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/blogs",
      name: "Blogs",
      icon: <ViewCompactIcon />,
      component: Blogs,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/Faqs",
      name: "Faqs",
      icon: <QuestionAnswerIcon />,
      component: FAQs,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/Logout",
      name: "Logout",
      icon: <LogoutIcon />,
      layout: "/admin",
      handleLogout: handleLogout,
      clickable: true,
    },
  ];
} else if (userRole !== "admin") {
  // Assuming userRole !== "admin" here, showing limited routes for non-admin roles
  routes = [
    {
      path: "/index",
      name: "Dashboard",
      icon: <LaptopIcon />,
      component: Index,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/products",
      name: "Products",
      icon: <CardGiftcardIcon />,
      component: Products,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/orders",
      name: "Orders",
      icon: <CardGiftcardIcon />,
      component: Orders,
      layout: "/admin",
      clickable: true,
    },
    {
      path: "/Logout",
      name: "Logout",
      icon: <LogoutIcon />,
      layout: "/admin",
      handleLogout: handleLogout,
      clickable: true,
    },
  ];
}

// Always add these routes for both admin and non-admin users
routes.push(
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-circle-08 text-pink",
    component: Login,
    layout: "/auth",
  }
);

export default routes;
