/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Avatar, Box, InputAdornment } from "@mui/material";
import { useRef } from "react";
import uploadIcon from "../../assets/uploadIcon.svg";
import { mediaUrl } from "config";
import CircularProgress from "@mui/material/CircularProgress";

const AddProducts = ({
  openModalProduct,
  handleModalProduct,
  getProducs,
  addingStoreId,
}) => {
  const fileInputRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    photo: "",
    price: "",
    quantity: "",
    weight: "",
    store: addingStoreId,
  });
  const [stores, setStores] = useState();
  const [loadingButton, setLoadingButton] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "price" || name === "quantity" || name === "weight") {
      if (/^\d*\.?\d*$/.test(value)) {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Image size must be less than 5MB.");
        return;
      }
      console.log("file", file);
      // const imageUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        photo: file,
      }));
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      photo: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.name === "") {
      return toast.error("Please enter Product name");
    }

    const formSubmitData = new FormData();

    formSubmitData.append("name", formData.name);
    formSubmitData.append("description", formData.description);

    formSubmitData.append("price", formData.price);

    formSubmitData.append("quantity", formData.quantity);

    formSubmitData.append("weight", formData.weight);

    if (Role === "admin") {
      formSubmitData.append("store", formData.store);
    } else {
      formSubmitData.append("store", localStorage.getItem("id"));
    }

    if (formData.photo) {
      formSubmitData.append("photo", formData.photo);
    } else if (formData.photo === null) {
      formSubmitData.append("photo", "");
    } else if (formData.photo === undefined) {
      formSubmitData.append("photo", "");
    }
    setLoadingButton(true);
    try {
      const response = await api("post", `/v1/products`, formSubmitData);
      toast.success("Product created successfully");
      getProducs();
      handleModalProduct();
    } catch (error) {
      console.error("Error Occurred");
    } finally {
      setLoadingButton(false);
    }
  };
  const [Storename, setStorename] = useState("");

  const getStore = async () => {
    const data = await api("get", "/v1/stores");
    setStores(data.stores);
  };

  useEffect(() => {
    getStore();
    const storedName = localStorage.getItem("name");
    setStorename(storedName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Role = localStorage.getItem("role");

  return (
    <>
      <Modal isOpen={openModalProduct} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalProduct}>
          Create a Product
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        alignItems="center"
                        mt={2}
                        mb={2}
                        gap="18px"
                      >
                        <Avatar
                          src={
                            formData.photo
                              ? typeof formData.photo === "string"
                                ? `${mediaUrl}/${formData.photo}`
                                : URL.createObjectURL(formData.photo)
                              : ""
                          }
                          style={{ width: "100px", height: "100px" }}
                        />
                        {formData.photo ? (
                          <Box margin="0" display="flex" alignItems="center">
                            <Button
                              onClick={handleRemove}
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                textTransform: "capitalize",
                                cursor: "pointer",
                                color: "#28A745",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        ) : (
                          <Box
                            borderRadius={"12px"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                accept="image/jpeg, image/png"
                              />
                              <img
                                src={uploadIcon}
                                alt="Upload Image"
                                style={{ cursor: "pointer" }}
                                onClick={handleImageClick}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's name here"
                          type="text"
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          // maxLength={20}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Store
                        </label>
                        {Role === "admin" ? (
                          <Input
                            style={{
                              textTransform: "capitalize",
                            }}
                            className="form-control-alternative text-default"
                            type="select"
                            name="store"
                            value={formData.store}
                            onChange={handleChange}
                            disabled
                          >
                            <option value="" disabled>
                              Select a store
                            </option>
                            {stores?.map((option) => (
                              <option key={option?._id} value={option?._id}>
                                {option?.name}
                              </option>
                            ))}
                          </Input>
                        ) : (
                          <Input
                            className="form-control-alternative text-default"
                            type="text"
                            name="store"
                            value={Storename}
                            disabled
                          />
                        )}
                        {/* <Input
                          className="form-control-alternative text-default"
                          type="select"
                          name="store"
                          value={formData.store}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            Select a store
                          </option>
                          {stores?.map((option) => (
                            <option key={option?._id} value={option?._id}>
                              {option?.name}
                            </option>
                          ))}
                        </Input> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Description
                        </label>
                        {/* <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="Description"
                          type="text"
                          value={formData.description}
                          name="description"
                          onChange={handleChange}
                        /> */}
                        <Input
                          style={{
                            resize: "none",
                          }}
                          type="textarea"
                          className="form-control-alternative text-default"
                          value={formData.description}
                          name="description"
                          onChange={handleChange}
                          rows={10} // Adjust rows based on expanded state
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Price
                        </label>
                        <InputGroup
                          style={{
                            boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                          }}
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                padding: "0% 2px 0% 14px",
                                borderTopLeftTadius: "6px",
                                borderBottomLeftRadius: "6px",
                              }}
                            >
                              $
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="Price"
                            type="text"
                            value={formData.price}
                            name="price"
                            onChange={handleChange}
                            maxLength={20}
                          />
                        </InputGroup>
                        {/* <Input
                          className="form-control-alternative text-default"
                          placeholder="Price"
                          type="text"
                          value={formData.price}
                          name="price"
                          onChange={handleChange}
                        /> */}
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Quantity
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Quantity"
                          type="phone"
                          value={formData.quantity}
                          name="quantity"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Weight</label>
                        <InputGroup
                          style={{
                            boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                          }}
                        >
                          <Input
                            className="form-control-alternative text-default"
                            required={true}
                            placeholder="Enter weight here"
                            type="text"
                            value={formData.weight}
                            name="weight"
                            onChange={handleChange}
                            maxLength={10}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                              }}
                            >
                              grams
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loadingButton}
            color="primary"
            onClick={handleSubmit}
          >
            {loadingButton ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Create"
            )}
          </Button>
          <Button onClick={handleModalProduct}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddProducts;
