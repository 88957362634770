/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useRef } from "react";
import uploadIcon from "../../assets/uploadIcon.svg";
import { mediaUrl } from "config";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { name: "Healthy Nutrition", title: "Healthy Nutrition" },
  { name: "Supplements", title: "Supplements" },
  { name: "Gym & Fitness", title: "Gym & Fitness" },
  { name: "Fitness Accessories", title: "Fitness Accessories" },
  { name: "Personal Care", title: "Personal Care" },
  { name: "Others", title: "Others" },
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddStore = ({ openModal, handleModal, user, setUser, getUsers }) => {
  const fileInputRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    photo: "",
    country: "",
    category: [], // Initialize as an array
    password: "",
    description: "",
    city: "",
    address: "",
  });

  const [address, setAddress] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Image size must be less than 5MB.");
        return;
      }
      // const imageUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        photo: file,
      }));
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      photo: null,
    }));
  };

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    setFormData((prevData) => ({
      ...prevData,
      // category: typeof value === "string" ? value.split(",") : value,
      category: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingButton(true);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[0-9]*$/;

    if (formData.name === "") {
      return toast.error("Please enter Store name");
      setLoadingButton(false);
    }
    // if (formData.photo === "") {
    //   return toast.error("Photo is required");
    // }

    if (!emailPattern.test(formData.email)) {
      return toast.error("Please enter a valid email address");
      setLoadingButton(false);
    }

    if (!phonePattern.test(formData.phone)) {
      return toast.error("Please enter a valid phone number");
      setLoadingButton(false);
    }

    const formSubmitData = new FormData();

    formSubmitData.append("name", formData.name);
    formSubmitData.append("email", formData.email);
    formSubmitData.append("phone", formData.phone);
    formSubmitData.append("description", formData.description);
    formData.category.forEach((cat) => {
      formSubmitData.append("category", cat);
    });
    formSubmitData.append("country", formData.country);
    formSubmitData.append("city", formData.city);
    formSubmitData.append("address", formData.address);
    formSubmitData.append("password", formData.password);

    if (formData.photo) {
      formSubmitData.append("photo", formData.photo);
    } else if (formData.photo === null) {
      formSubmitData.append("photo", "");
    } else if (formData.photo === undefined) {
      formSubmitData.append("photo", "");
    }
    try {
      const response = await api("post", `/v1/stores`, formSubmitData);
      toast.success("Store created successfully");
      getUsers();
      handleModal();
    } catch (error) {
      console.error("Error Occurred");
    } finally {
      setLoadingButton(false);
    }
  };

  const getAddress = async () => {
    try {
      const response = await fetch(
        "https://bigazure.com/api/json_v3/cities/get_cities.php"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setAddress(data?.response);
    } catch (error) {
      toast.error("Error fetching address:");
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  const defaultProps = {
    options: address,
    getOptionLabel: (option) => option?.CITY_NAME,
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Create a Store
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        alignItems="center"
                        mt={2}
                        mb={2}
                        gap="18px"
                      >
                        <Avatar
                          src={
                            formData.photo
                              ? typeof formData.photo === "string"
                                ? `${mediaUrl}/${formData.photo}`
                                : URL.createObjectURL(formData.photo)
                              : ""
                          }
                          sx={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                          }}
                        />
                        {formData.photo ? (
                          <Box margin="0" display="flex" alignItems="center">
                            <Button
                              onClick={handleRemove}
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                textTransform: "capitalize",
                                cursor: "pointer",
                                color: "#28A745",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        ) : (
                          <Box
                            borderRadius={"12px"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                accept="image/jpeg, image/png"
                              />
                              <img
                                src={uploadIcon}
                                alt="Upload Image"
                                style={{ cursor: "pointer" }}
                                onClick={handleImageClick}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's name here"
                          type="text"
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          // maxLength={20}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Email</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's email here"
                          type="text"
                          value={formData.email}
                          name="email"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Description
                        </label>
                        {/*<Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="Description"
                          type="text"
                          value={formData.description}
                          name="description"
                          onChange={handleChange}
                        /> */}
                        <Input
                          style={{
                            resize: "none",
                          }}
                          type="textarea"
                          className="form-control-alternative text-default"
                          value={formData.description}
                          name="description"
                          onChange={handleChange}
                          rows={10} // Adjust rows based on expanded state
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="SelectMUiRow city_inputunderline">
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Phone no
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Phone no"
                          type="phone"
                          value={formData.phone}
                          name="phone"
                          onChange={handleChange}
                          maxLength={11}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Category
                        </label>

                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            multiple
                            displayEmpty
                            value={personName}
                            onChange={handleChangeSelect}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return (
                                  <p className="SelectMUiRow_P">Categories</p>
                                );
                              }
                              return selected.join(", ");
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {names.map((nameObj) => (
                              <MenuItem
                                key={nameObj.name}
                                value={nameObj.name}
                                style={getStyles(
                                  nameObj.name,
                                  personName,
                                  theme
                                )}
                              >
                                {nameObj.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Col>
                    {/* <Input
                          className="form-control-alternative text-default"
                          placeholder="Category"
                          type="select"
                          value={formData.category}
                          name="category"
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            Select a category
                          </option>
                          {options.map((option) => (
                            <option key={option.name} value={option.name}>
                              {option.title}
                            </option>
                          ))}
                        </Input> */}
                  </Row>
                  <Row className="city_inputunderline ">
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Country
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="Country"
                          type="text"
                          value={formData.country}
                          name="country"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          City
                        </label>
                        <Autocomplete
                          className="automComplete"
                          style={{
                            boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                            padding: "0 2%",
                            border: "none",
                          }}
                          {...defaultProps}
                          id="disable-close-on-select"
                          disableCloseOnSelect
                          onChange={(event, newValue) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              city: newValue?.CITY_CODE || "", // Store CITY_CODE
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Enter city"
                              {...params}
                              variant="standard"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Address
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          placeholder="Address"
                          type="text"
                          value={formData.address}
                          name="address"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Password
                        </label>
                        <div className="input-group">
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            value={formData.password}
                            name="password"
                            onChange={handleChange}
                            maxLength={8}
                            autoComplete="new-password"
                          />
                          <div className="input-group-append">
                            <IconButton onClick={toggleShowPassword}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loadingButton}
            color="primary"
            onClick={handleSubmit}
          >
            {loadingButton ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Create"
            )}
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddStore;
