// reactstrap components
import { useState, useEffect } from "react";
import api from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card, CardHeader, Button, Media, Table, Row, Input } from "reactstrap";

// core components
// import Header from "components/Headers/Header.js";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import {
  Avatar,
  Box,
  Dialog,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import LinearProgress from "@mui/material/LinearProgress";
import ViewBookings from "./ViewBookings";
import SearchIcon from "@mui/icons-material/Search";

const Bookings = () => {
  //   const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);

  //   const [editModal, setEditModal] = useState(false);
  //   const { users } = Store();
  const [loading, setLoading] = useState(true);

  const [booking, setBooking] = useState();
  // const history = useHistory();
  //   const [editblog, setEditBlog] = useState({
  //     _id: "",
  //     title: "",
  //     detail: "",
  //     coach: "",
  //     photo: "",
  //     link: "",
  //   });
  const [searchName, setSearchName] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);

  const getUsers = async () => {
    const data = await api("get", "/v1/bookings");
    setBooking(data?.bookings);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSlotsSearch = async () => {
    if (searchName) {
      const data = await api("get", `/v1/bookings?name=${searchName}`);
      setBooking(data?.bookings || []);
      setSearchPerformed(true);
    } else {
      getUsers(); // If searchName is empty, show all data
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSlotsSearch();
    }
  };

  const handleDelete = (id) => {
    api("delete", `/v1/bookings/${id}`).then(() => {
      toast.success("Booking deleted successfully");
      getUsers();
    });
  };

  //   const handleModal = () => {
  //     setOpenModal((open) => !open);
  //     return openModal;
  //   };

  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setSelectedBookingId(id);
    return openModalView;
  };

  //   const handleEditModal = (item) => {
  //     if (item) {
  //       setEditBlog({
  //         _id: item._id,
  //         title: item.title,
  //         detail: item.detail,
  //         coach: item.coach,
  //         photo: item.photo,
  //         link: item.link,
  //       });
  //     }

  //     setEditModal((open) => !open);
  //     return editModal;
  //   };

  return (
    <>
      <div
        style={{
          paddingTop: "30px",
          padding: "0 2%",
        }}
      >
        <Row
          style={{
            padding: "2% 0",
          }}
        >
          <div
            className="col"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card
              style={{
                border: "1px solid #E4E5E8",
              }}
              className="shadow"
            >
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">All Bookings</h3>
                  <div
                    className="form-control-alternative text-default"
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: "1%",
                      borderRadius: "6px",
                    }}
                  >
                    <Input
                      style={{
                        border: "none",
                      }}
                      placeholder="Search by name here"
                      type="text"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      onKeyDown={handleKeyPress}
                      maxLength={20}
                    />
                    <SearchIcon
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={getSlotsSearch}
                    />
                  </div>
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        channel Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Coach Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Customer Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Status
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {booking.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          Booking Not Found
                        </td>
                      </tr>
                    ) : (
                      booking.map((item, index) => {
                        return (
                          <tr
                            style={{
                              color: "black",
                            }}
                          >
                            <th scope="row">{index + 1}</th>

                            <td
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.channelName?.length > 30
                                ? item?.channelName?.substring(0, 30) + "..."
                                : item?.channelName
                                ? item?.channelName
                                : "NA"}
                            </td>
                            <td>
                              {item?.coach?.name?.length > 30
                                ? item?.coach?.name?.substring(0, 30) + "..."
                                : item?.coach?.name}
                            </td>
                            <td>
                              {item?.customer?.name?.length > 30
                                ? item?.customer?.name?.substring(0, 30) + "..."
                                : item?.customer?.name}
                            </td>

                            <td
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.status?.length > 30
                                ? item?.status?.substring(0, 30) + "..."
                                : item?.status}
                            </td>
                            <td
                              className="text-left"
                              style={{
                                display: "flex",
                                gap: "6px",
                              }}
                            >
                              <VisibilityIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleModalView(item._id)}
                              />
                              <DeleteIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDelete(item._id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>

        {/* {openModal && (
          <AddBlogs
            openModal={openModal}
            handleModal={handleModal}
            getUsers={getUsers}
          />
        )}
        {editModal && (
          <EditBlogs
            openModal={editModal}
            handleModal={handleEditModal}
            editblog={editblog}
            setEditBlog={setEditBlog}
            getUsers={getUsers}
          />
        )}*/}
        {openModalView && (
          <ViewBookings
            openModalView={openModalView}
            handleModalView={handleModalView}
            selectedBookingId={selectedBookingId}
          />
        )}
      </div>
    </>
  );
};

export default Bookings;
