/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Avatar, Box, CircularProgress } from "@mui/material";
import { useRef } from "react";
import uploadIcon from "../../assets/uploadIcon.svg";
import { mediaUrl } from "config";

const AddBlogs = ({ openModal, handleModal, user, setUser, getUsers }) => {
  const fileInputRef = useRef();
  const [formData, setFormData] = useState({
    title: "",
    detail: "",
    coach: "",
    photo: "",
    link: "",
  });
  const [coaches, setCoaches] = useState();
  const [loadingButton, setLoadingButton] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Image size must be less than 5MB.");
        return;
      }
      console.log("file", file);
      // const imageUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        photo: file,
      }));
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      photo: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingButton(true);

    const formSubmitData = new FormData();
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (formData.title === "") {
      return toast.error("Please enter title");
    }
    if (formData.link !== "") {
      const linkValue = formData.link;
      if (!urlRegex.test(linkValue)) {
        toast.error("Invalid link format");
      } else {
        formSubmitData.append("link", linkValue);
      }
    } else {
      formSubmitData.append("link", formData.link);
    }

    formSubmitData.append("title", formData.title);
    formSubmitData.append("detail", formData.detail);
    formSubmitData.append("coach", formData.coach);

    if (formData.photo) {
      formSubmitData.append("photo", formData.photo);
    } else if (formData.photo === null) {
      formSubmitData.append("photo", "");
    } else if (formData.photo === undefined) {
      formSubmitData.append("photo", "");
    }
    try {
      const response = await api("post", `/v1/blogs`, formSubmitData, {});
      toast.success("Blog created successfully");
      getUsers();
      handleModal();
    } catch (error) {
      console.error("Error Occurred");
    } finally {
      setLoadingButton(false);
    }
  };

  const getCoaches = async () => {
    const data = await api("get", "/v1/users?role=coach");
    setCoaches(data.users);
  };

  useEffect(() => {
    getCoaches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Create a Blog
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        alignItems="center"
                        mt={2}
                        mb={2}
                        gap="18px"
                      >
                        <Avatar
                          src={
                            formData.photo
                              ? typeof formData.photo === "string"
                                ? `${mediaUrl}/${formData.photo}`
                                : URL.createObjectURL(formData.photo)
                              : ""
                          }
                          style={{ width: "100px", height: "100px" }}
                        />
                        {formData.photo ? (
                          <Box margin="0" display="flex" alignItems="center">
                            <Button
                              onClick={handleRemove}
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                textTransform: "capitalize",
                                cursor: "pointer",
                                color: "#28A745",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        ) : (
                          <Box
                            borderRadius={"12px"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                accept="image/jpeg, image/png"
                              />
                              <img
                                src={uploadIcon}
                                alt="Upload Image"
                                style={{ cursor: "pointer" }}
                                onClick={handleImageClick}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Title</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Title"
                          type="text"
                          value={formData.title}
                          name="title"
                          onChange={handleChange}
                          // maxLength={20}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Coach
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          type="select"
                          name="coach"
                          value={formData.coach}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            Select a Coach
                          </option>
                          {coaches?.map((option) => (
                            <option key={option?._id} value={option?._id}>
                              {option?.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Detail
                        </label>
                        {/* <Input
                          className="form-control-alternative text-default"
                          placeholder="Detail"
                          type="text"
                          value={formData.detail}
                          name="detail"
                          onChange={handleChange}
                        /> */}
                        <Input
                          style={{
                            resize: "none",
                          }}
                          type="textarea"
                          className="form-control-alternative text-default"
                          value={formData.detail}
                          name="detail"
                          onChange={handleChange}
                          rows={10} // Adjust rows based on expanded state
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label className="form-control-label">Link</label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="http://abc.xyz"
                          type="text"
                          value={formData.link}
                          name="link"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loadingButton}
            color="primary"
            onClick={handleSubmit}
          >
            {loadingButton ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Create"
            )}
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddBlogs;
