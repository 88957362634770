// reactstrap components
import { useState, useEffect } from "react";
import api from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card, CardHeader, Button, Table, Row, Input } from "reactstrap";

// core components
// import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

import LinearProgress from "@mui/material/LinearProgress";
import ViewOrders from "./ViewOrders";
import SearchIcon from "@mui/icons-material/Search";

const Orders = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);

  //   const [editModal, setEditModal] = useState(false);

  //   const [editFAQ, setEditFAQ] = useState({
  //     _id: "",
  //     question: "",
  //     answer: "",
  //   });
  const Role = localStorage.getItem("role");
  const _id = localStorage.getItem("id");

  const getOrders = async () => {
    if (Role !== "admin") {
      const data = await api("get", `/v1/orders?store=${_id}`);
      setOrders(data?.orders);
      setLoading(false);
    } else {
      const data = await api("get", `/v1/orders`);
      setOrders(data?.orders);
      setLoading(false);
    }
  };

  console.log("🚀 ~ Orders ~ orders:", orders);

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id) => {
    api("delete", `/v1/orders/${id}`).then(() => {
      toast.success("Order deleted successfully");
      getOrders();
    });
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setOrderId(id);
    return openModalView;
  };
  const getSlotsSearch = async () => {
    if (searchName) {
      const data = await api("get", `/v1/orders?name=${searchName}`);
      setOrders(data?.orders || []);
      setSearchPerformed(true);
    } else {
      getOrders();
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSlotsSearch();
    }
  };

  //   const handleEditModal = (item) => {
  //     if (item) {
  //       setEditFAQ({
  //         _id: item._id,
  //         question: item.question,
  //         answer: item.answer,
  //       });
  //     }

  //     setEditModal((open) => !open);
  //     return editModal;
  //   };

  return (
    <>
      <div
        style={{
          paddingTop: "30px",
          padding: "0 2%",
        }}
      >
        <Row
          style={{
            padding: "2% 0",
          }}
        >
          <div
            className="col"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card
              style={{
                border: "1px solid #E4E5E8",
              }}
              className="shadow"
            >
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">All Orders</h3>
                  <div
                    className="form-control-alternative text-default"
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: "1%",
                      borderRadius: "6px",
                    }}
                  >
                    <Input
                      style={{
                        border: "none",
                      }}
                      placeholder="Search by name here"
                      type="text"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      onKeyDown={handleKeyPress}
                      maxLength={20}
                    />
                    <SearchIcon
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={getSlotsSearch}
                    />
                  </div>
                  {/* <Button
                    style={{
                      backgroundColor: "#218838",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "none",
                    }}
                    color="primary"
                    size="md"
                    onClick={handleModal}
                  >
                    Create FAQs
                  </Button> */}
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Customer Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Store Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Total Price
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Receiver Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Quantity
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Role !== "admin" && orders.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          Order Not Found
                        </td>
                      </tr>
                    ) : orders.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          Order Not Found
                        </td>
                      </tr>
                    ) : (
                      orders.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            color: "black",
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {item?.customer?.name?.length > 30
                              ? item?.customer?.name?.substring(0, 30) + "..."
                              : item?.customer?.name}
                          </td>
                          <td>
                            {item?.store?.name?.length > 30
                              ? item?.store?.name?.substring(0, 30) + "..."
                              : item?.store?.name}
                          </td>
                          <td>
                            $
                            {item?.totalPrice
                              ? parseFloat(item.totalPrice).toFixed(3)
                              : "0.000"}
                          </td>

                          <td>
                            {item?.delivery_data?.name?.length > 30
                              ? item?.delivery_data?.name?.substring(0, 30) +
                                "..."
                              : item?.delivery_data?.name}
                          </td>
                          <td>
                            {item?.products?.reduce(
                              (total, product) => total + product.quantity,
                              0
                            )}
                          </td>
                          <td
                            className="text-left"
                            style={{
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                color: "#007BFF",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleModalView(item._id)}
                            />
                            {/* Uncomment/EditCalendarIcon when needed */}
                            {/* <EditCalendarIcon
              sx={{
                color: "#007BFF",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleEditModal(item)}
            /> */}
                            <DeleteIcon
                              sx={{
                                color: "#007BFF",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(item._id)}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>

        {/* {openModal && (
          <AddFAQ
            openModal={openModal}
            handleModal={handleModal}
            getUsers={getUsers}
          />
        )}
        {editModal && (
          <EditFAQ
            openModal={editModal}
            handleModal={handleEditModal}
            editFAQ={editFAQ}
            setEditFAQ={setEditFAQ}
            getUsers={getUsers}
          />
        )}
          */}
        {openModalView && (
          <ViewOrders
            openModalView={openModalView}
            handleModalView={handleModalView}
            orderId={orderId}
          />
        )}
      </div>
    </>
  );
};

export default Orders;
