import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import { Avatar, Box } from "@mui/material";
import api from "api";
import { useEffect } from "react";
import { mediaUrl } from "config";

const ViewProduct = ({ openModalView, handleModalView, ProductId }) => {
  const [products, setPrducts] = useState();

  const getUsersByid = async () => {
    const data = await api("get", `/v1/products/${ProductId}`);
    setPrducts(data);
  };

  console.log("🚀 ~ ViewProduct ~ prducts:", products);

  useEffect(() => {
    getUsersByid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          Product
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        mt={2}
                        mb={2}
                        gap="16px"
                      >
                        {/* <Avatar
                          alt="Avatar"
                          src={
                            products?.product?.photo
                              ? `${mediaUrl}/${products?.product?.photo}`
                              : ""
                          }
                          sx={{ width: "64px", height: "64px" }}
                        /> */}
                        {products?.product?.photo ? (
                          <a
                            href={`${mediaUrl}/${products?.product?.photo}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          >
                            <Avatar
                              alt="Avatar"
                              src={`${mediaUrl}/${products?.product?.photo}`}
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50px",
                              }}
                            />
                          </a>
                        ) : (
                          <Avatar
                            alt="Avatar"
                            src={`${mediaUrl}/${products?.product?.photo}`}
                            sx={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          />
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's name here"
                          type="text"
                          value={products?.product?.name}
                          name="name"
                          maxLength={20}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Store
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          placeholder="Category"
                          type="text"
                          value={products?.product?.store?.name}
                          name="category"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Description
                        </label>
                        {/* <Input
                          className="form-control-alternative text-default"
                          placeholder="Description"
                          type="text"
                          value={products?.product?.description}
                          name="description"
                          disabled
                        /> */}
                        <Input
                          style={{
                            resize: "none",
                          }}
                          type="textarea"
                          className="form-control-alternative text-default"
                          value={products?.product?.description}
                          name="description"
                          disabled
                          rows={10} // Adjust rows based on expanded state
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Quantity</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter user's email here"
                          type="text"
                          value={products?.product.quantity}
                          name="email"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Price
                        </label>
                        <InputGroup
                          style={
                            {
                              // boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                            }
                          }
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                backgroundColor: "#E9ECEF",
                                padding: "0% 2px 0% 14px",
                                borderTopLeftTadius: "6px",
                                borderBottomLeftRadius: "6px",
                              }}
                            >
                              $
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="Phone no"
                            type="phone"
                            value={products?.product?.price}
                            name="phone"
                            maxLength={11}
                            disabled
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Weight
                        </label>
                        <InputGroup
                          style={{
                            boxShadow: `0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)`,
                          }}
                        >
                          <Input
                            className="form-control-alternative text-default"
                            placeholder="Weight"
                            type="text"
                            value={products?.product?.weight}
                            name="weight"
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                backgroundColor: "#E9ECEF",
                              }}
                            >
                              grams
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewProduct;
