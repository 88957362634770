import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Avatar, Box } from "@mui/material";
import api from "api";
import { useEffect } from "react";
import { mediaUrl } from "config";

const ViewUser = ({ openModalView, handleModalView, userId }) => {
  const [users, setUsers] = useState();

  const getUsersByid = async () => {
    const data = await api("get", `/v1/users/${userId}`);
    setUsers(data);
  };

  useEffect(() => {
    getUsersByid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          Customer
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "0" }}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        mt={2}
                        mb={2}
                        gap="16px"
                      >
                        {/* <Avatar
                          alt="Avatar"
                          src={
                          users
                              ?.user?.photo
                              ? `${mediaUrl}/${users?.user?.photo}`
                              : ""
                          }
                          sx={{ width: "64px", height: "64px" }}
                        /> */}
                        {users?.user?.photo ? (
                          <a
                            href={`${mediaUrl}/${users?.user?.photo}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          >
                            <Avatar
                              alt="Avatar"
                              src={`${mediaUrl}/${users?.user?.photo}`}
                              sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50px",
                              }}
                            />
                          </a>
                        ) : (
                          <Avatar
                            alt="Avatar"
                            src={`${mediaUrl}/${users?.user?.photo}`}
                            sx={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          />
                        )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          required={true}
                          type="text"
                          value={users?.user?.name}
                          name="name"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label"> Email</label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="text"
                          value={users?.user?.email}
                          name="email"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Role
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          id="input-role"
                          type="text"
                          value={users?.user?.role}
                          name="role"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label"> Phone no.</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="text"
                          value={users?.user?.phone}
                          name="phone"
                          maxLength="11"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Weight
                        </label>
                        <InputGroup>
                          <Input
                            className="form-control-alternative text-default"
                            id="input-role"
                            type="text"
                            value={users?.user?.user_data?.weight}
                            name="role"
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                backgroundColor: "#E9ECEF",
                              }}
                            >
                              pound
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Height</label>
                        <InputGroup>
                          <Input
                            className="form-control-alternative text-default"
                            required={true}
                            type="text"
                            value={users?.user?.user_data?.height}
                            name="phone"
                            maxLength="11"
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                border: "none",
                                backgroundColor: "#E9ECEF",
                              }}
                            >
                              feet
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Activity
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          id="input-role"
                          type="text"
                          value={users?.user?.user_data?.activity}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Concern</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          type="text"
                          value={users?.user?.user_data?.concern.join(", ")}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Country
                        </label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          id="input-role"
                          type="text"
                          value={users?.user?.user_data?.country}
                          name="role"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">City</label>
                        <Input
                          style={{
                            textTransform: "capitalize",
                          }}
                          className="form-control-alternative text-default"
                          required={true}
                          type="text"
                          value={users?.user?.user_data?.city}
                          name="phone"
                          maxLength="11"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewUser;
