// reactstrap components
import { useState, useEffect } from "react";
import api from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card, CardHeader, Button, Media, Table, Row } from "reactstrap";

// core components
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";

import LinearProgress from "@mui/material/LinearProgress";
import { mediaUrl } from "config";
import AddProducts from "./AddProducts";
import ViewProduct from "./ViewProduct";
import EditProducts from "./EditProducts";

const Stores = () => {
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [products, setProducts] = useState();

  const [editModal, setEditModal] = useState(false);

  const [productsData, setProductsData] = useState({
    _id: "",
    name: "",
    description: "",
    price: "",
    quantity: "",
    weight: "",
    store: "",
    photo: "",
  });

  const getUsers = async () => {
    const data = await api("get", "/v1/products");
    setProducts(data.products);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleCheck = localStorage.getItem("role");
  const idCheck = localStorage.getItem("id");

  const handleDelete = (id, storeId) => {
    if (roleCheck !== "admin") {
      if (idCheck === storeId) {
        api("delete", `/v1/products/${id}`).then(() => {
          toast.success("Product deleted successfully");
          getUsers();
        });
      } else toast.error("You cannot delete other products");
    } else
      api("delete", `/v1/products/${id}`).then(() => {
        toast.success("Product deleted successfully");
        getUsers();
      });
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setSelectedProductId(id);
    return openModalView;
  };

  const handleEditModal = (item) => {
    if (item) {
      setProductsData({
        _id: item._id,
        name: item.name,
        description: item.description,
        price: item.price,
        quantity: item.quantity,
        weight: item.weight,
        store: item.store,
        photo: item.photo,
      });
    }

    setEditModal((open) => !open);
    return editModal;
  };

  return (
    <>
      <div
        style={{
          paddingTop: "30px",
          padding: "0 2%",
        }}
      >
        <Row
          style={{
            padding: "2% 0",
          }}
        >
          <div
            className="col"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card
              style={{
                border: "1px solid #E4E5E8",
              }}
              className="shadow"
            >
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">All Products</h3>
                  {roleCheck !== "admin" ? (
                    <Button
                      style={{
                        backgroundColor: "#218838",
                        border: "none",
                        cursor: "pointer",
                        boxShadow: "none",
                      }}
                      color="primary"
                      size="md"
                      onClick={handleModal}
                    >
                      Create Product
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Image
                      </th>

                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Store Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Product Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Quantity
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Weight{" "}
                        <sub
                          style={{
                            fontWeight: "500",
                            color: "black",
                            fontSize: "10px",
                            textTransform: "lowercase",
                          }}
                        >
                          g
                        </sub>
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Description
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products
                      .filter((item) => {
                        if (roleCheck !== "admin") {
                          return item.store._id === idCheck;
                        }
                        return true;
                      })
                      .map((item, index) => {
                        return (
                          <tr
                            style={{
                              color: "black",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media className="align-items-center">
                                  <Avatar
                                    src={
                                      item?.photo
                                        ? `${mediaUrl}/${item?.photo}`
                                        : ""
                                    }
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    {item.name.split("")[0].toUpperCase()}
                                  </Avatar>
                                </Media>
                              </Media>
                            </th>
                            <td
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.store?.name?.length > 30
                                ? item?.store?.name?.substring(0, 30) + "..."
                                : item?.store?.name}
                            </td>
                            <td
                            // style={{
                            //   textTransform: "capitalize",
                            // }}
                            >
                              {item?.name?.length > 30
                                ? item?.name?.substring(0, 30) + "..."
                                : item?.name}
                            </td>
                            <td>
                              {item.quantity?.length > 30
                                ? item.quantity?.substring(0, 30) + "..."
                                : item.quantity}
                            </td>
                            <td>
                              {item.weight?.length > 30
                                ? item.weight?.substring(0, 30) + "..."
                                : item.weight}{" "}
                              {item.weight ? (
                                <sub
                                  style={{
                                    fontWeight: "500",
                                    color: "black",
                                    fontSize: "10px",
                                    textTransform: "lowercase",
                                  }}
                                >
                                  g
                                </sub>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {item.description?.length > 30
                                ? item.description?.substring(0, 30) + "..."
                                : item.description}
                            </td>
                            <td
                              className="text-left"
                              style={{
                                display: "flex",
                                gap: "6px",
                              }}
                            >
                              <VisibilityIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleModalView(item._id)}
                              />
                              <EditCalendarIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditModal(item)}
                              />
                              <DeleteIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDelete(item._id, item.store._id)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </Card>
            {/* <Box
              display={"flex"}
              width="100%"
              // backgroundColor="red"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: "#212529",
                  fontSize: "16px",
                }}
              >
                Showing 1 to 10 of 15 entries
              </Typography>
              <Box display="flex" gap="0px" font>
                <Button
                  style={{
                    margin: "0px",
                    backgroundColor: "white",
                    borderTopLeftRadius: "6px ",
                    borderTopRightRadius: "0px",
                    borderBottomLeftRadius: "6px",
                    borderBottomRightRadius: "0px",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                    color: "#6c757d",
                  }}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0px",
                    backgroundColor: "white",
                    borderRadius: "0",
                    border: "1px solid #dee2e6",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    color: "#6c757d",
                  }}
                >
                  1
                </Button>
                <Button
                  style={{
                    margin: "0px",
                    backgroundColor: "white",
                    borderRadius: "0px",
                    borderTop: "1px solid #dee2e6",
                    borderBottom: "1px solid #dee2e6",
                    borderLeft: "1px solid #dee2e6",
                    borderRight: "none",

                    boxShadow: "none",
                    color: "#6c757d",
                  }}
                >
                  2
                </Button>
                <Button
                  style={{
                    margin: "0px",
                    backgroundColor: "white",
                    borderTopLeftRadius: "0px ",
                    borderTopRightRadius: "6px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "6px",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                    // color: "#6c757d",
                    color: " #007bff",
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box> */}
          </div>
        </Row>
      </div>
      {roleCheck !== "admin" && openModal && (
        <AddProducts
          openModal={openModal}
          handleModal={handleModal}
          getUsers={getUsers}
        />
      )}
      {editModal && (
        <EditProducts
          openModal={editModal}
          handleModal={handleEditModal}
          productsData={productsData}
          setProductsData={setProductsData}
          getUsers={getUsers}
        />
      )}
      {openModalView && (
        <ViewProduct
          openModalView={openModalView}
          handleModalView={handleModalView}
          ProductId={selectedProductId}
        />
      )}
    </>
  );
};

export default Stores;
