/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { CircularProgress } from "@mui/material";

const AddFAQ = ({ openModal, handleModal, getUsers }) => {
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingButton(true);

    try {
      const response = await api("post", `/v1/faqs`, formData, {});
      toast.success("FAQs created successfully");
      getUsers();
      handleModal();
    } catch (error) {
      console.error("Error Occurred");
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Create FAQs
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="10" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Question</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Write Question"
                          type="text"
                          value={formData.question}
                          name="question"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="10" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Answer
                        </label>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "1%",

                            resize: "none" /* Disable resizing */,
                          }}
                          className="form-control-alternative text-default"
                          placeholder="Write Answer"
                          type="text"
                          value={formData.answer}
                          name="answer"
                          onChange={handleChange}
                          rows={10}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loadingButton}
            color="primary"
            onClick={handleSubmit}
          >
            {loadingButton ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Create"
            )}
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddFAQ;
