// reactstrap components
import { useState, useEffect } from "react";
import api from "../../api";
import { Card, CardHeader, Button, Media, Table, Row } from "reactstrap";

// core components
// import Header from "components/Headers/Header.js";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import LinearProgress from "@mui/material/LinearProgress";
import { mediaUrl } from "config";
import AddStore from "./AddStore";
import ViewStore from "./ViewStore";
import EditStore from "./EditStore";
import InventoryIcon from "@mui/icons-material/Inventory";
import AddProducts from "./AddProducts";

const Stores = () => {
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [selectedStoreId, setSelectedstoreId] = useState(null);
  const [addingStoreId, setAddingstoreId] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [openModalProduct, setOpenModalProduct] = useState(false);
  const [products, setProducts] = useState();

  const [stores, setStores] = useState();

  const [storesData, setStoresData] = useState({
    _id: "",
    name: "",
    email: "",
    description: "",
    phone: "",
    category: "",
    country: "",
    city: "",
    address: "",
    photo: "",
    password: "",
  });

  const getUsers = async () => {
    const data = await api("get", "/v1/stores");
    setStores(data.stores);
    setLoading(false);
  };
  const getProducts = async () => {
    const data = await api("get", "/v1/products");
    setProducts(data.products);
  };

  useEffect(() => {
    getUsers();
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id) => {
    api("delete", `/v1/stores/${id}`).then(() => {
      toast.success("Store deleted successfully");
      getUsers();
    });
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };
  const handleModalProduct = (item) => {
    console.log("🚀 ~ handleModalProduct ~ item:", item);
    setOpenModalProduct((open) => !open);
    setAddingstoreId(item);
    return openModalProduct;
  };

  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setSelectedstoreId(id);
    return openModalView;
  };

  const handleEditModal = (item) => {
    if (item) {
      setStoresData({
        _id: item._id,
        name: item.name,
        email: item.email,
        description: item.description,
        phone: item.phone,
        category: item.category,
        country: item.country,
        city: item.city,
        address: item.address,
        password: item.password,
        photo: item.photo,
      });
    }

    setEditModal((open) => !open);
    return editModal;
  };

  return (
    <>
      <div
        style={{
          paddingTop: "30px",
          padding: "0 2%",
        }}
      >
        <Row
          style={{
            padding: "2% 0",
          }}
        >
          <div
            className="col"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card
              style={{
                border: "1px solid #E4E5E8",
              }}
              className="shadow"
            >
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">All Stores</h3>
                  <Button
                    style={{
                      backgroundColor: "#218838",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "none",
                    }}
                    color="primary"
                    size="md"
                    onClick={handleModal}
                  >
                    Create Store
                  </Button>
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Image
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Email
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Phone Number
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Description
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Country
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {stores.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          Stores Not Found
                        </td>
                      </tr>
                    ) : (
                      stores.map((item, index) => {
                        return (
                          <tr
                            style={{
                              color: "black",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media className="align-items-center">
                                  <Avatar
                                    src={
                                      item?.photo
                                        ? `${mediaUrl}/${item?.photo}`
                                        : ""
                                    }
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    {item.name.split("")[0].toUpperCase()}
                                  </Avatar>
                                </Media>
                              </Media>
                            </th>
                            <td
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.name?.length > 30
                                ? item?.name?.substring(0, 30) + "..."
                                : item?.name}
                            </td>
                            <td
                            // style={{
                            //   textTransform: "capitalize",
                            // }}
                            >
                              {item.email?.length > 30
                                ? item.email?.substring(0, 30) + "..."
                                : item.email}
                            </td>
                            <td>
                              {item.phone?.length > 30
                                ? item.phone?.substring(0, 30) + "..."
                                : item.phone}
                            </td>
                            <td>
                              {item.description?.length > 30
                                ? item.description?.substring(0, 30) + "..."
                                : item.description}
                            </td>
                            <td>
                              {item.country?.length > 30
                                ? item.country?.substring(0, 30) + "..."
                                : item.country}
                            </td>

                            <td
                              className="text-left"
                              style={{
                                display: "flex",
                                gap: "6px",
                              }}
                            >
                              <VisibilityIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleModalView(item._id)}
                              />
                              <EditCalendarIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditModal(item)}
                              />
                              <DeleteIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDelete(item._id)}
                              />
                              <InventoryIcon
                                sx={{
                                  color: "#007BFF",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleModalProduct(item._id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
            {/* <Box
              display={"flex"}
              width="100%"
              // backgroundColor="red"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: "#212529",
                  fontSize: "16px",
                }}
              >
                Showing 1 to 10 of 15 entries
              </Typography>
              <Box display="flex" gap="0px" font>
                <Button
                  style={{
                    margin: "0px",
                    backgroundColor: "white",
                    borderTopLeftRadius: "6px ",
                    borderTopRightRadius: "0px",
                    borderBottomLeftRadius: "6px",
                    borderBottomRightRadius: "0px",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                    color: "#6c757d",
                  }}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0px",
                    backgroundColor: "white",
                    borderRadius: "0",
                    border: "1px solid #dee2e6",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    color: "#6c757d",
                  }}
                >
                  1
                </Button>
                <Button
                  style={{
                    margin: "0px",
                    backgroundColor: "white",
                    borderRadius: "0px",
                    borderTop: "1px solid #dee2e6",
                    borderBottom: "1px solid #dee2e6",
                    borderLeft: "1px solid #dee2e6",
                    borderRight: "none",

                    boxShadow: "none",
                    color: "#6c757d",
                  }}
                >
                  2
                </Button>
                <Button
                  style={{
                    margin: "0px",
                    backgroundColor: "white",
                    borderTopLeftRadius: "0px ",
                    borderTopRightRadius: "6px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "6px",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                    // color: "#6c757d",
                    color: " #007bff",
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box> */}
          </div>
        </Row>
      </div>
      {openModal && (
        <AddStore
          openModal={openModal}
          handleModal={handleModal}
          getUsers={getUsers}
        />
      )}
      {editModal && (
        <EditStore
          openModal={editModal}
          handleModal={handleEditModal}
          storesData={storesData}
          setStoresData={setStoresData}
          getUsers={getUsers}
        />
      )}
      {openModalView && (
        <ViewStore
          openModalView={openModalView}
          handleModalView={handleModalView}
          storeId={selectedStoreId}
        />
      )}
      {openModalProduct && (
        <AddProducts
          openModalProduct={openModalProduct}
          handleModalProduct={handleModalProduct}
          getProducs={getProducts}
          addingStoreId={addingStoreId}
        />
      )}
    </>
  );
};

export default Stores;
