// reactstrap components
import { useState, useEffect, useRef } from "react";
import api from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Store, UpdateStore } from "../../StoreContext";
import { Card, CardHeader, Button, Media, Table, Row } from "reactstrap";

// core components
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Avatar, Box, Typography } from "@mui/material";

import LinearProgress from "@mui/material/LinearProgress";
// import AddUser from "./AddUser";
import ViewUser from "./ViewUser";
import { mediaUrl } from "config";
import EditUser from "./EditUser";
import AddUser from "./AddUser";

const Users = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const { users } = Store();
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const updateStore = UpdateStore();
  const [user, setUser] = useState({
    _id: "",
    name: "",
    email: "",
    role: "",
    phone: "",
    photo: "",
    user_data: {
      height: "",
      weight: "",
      country: "",
      city: "",
      gender: "",
      activity: "",
    },
  });

  const getUsers = async () => {
    const data = await api("get", "/v1/users?role=customer");
    updateStore({ users: data.users });
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id) => {
    console.log("🚀 ~ handleDelete ~ id:", id);
    api("delete", `/v1/users/${id}`).then(() => {
      toast.success("User deleted successfully");
      getUsers();
    });
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };
  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setSelectedUserId(id);
    return openModalView;
  };
  const handleEditModal = (item) => {
    if (item) {
      setUser({
        _id: item._id,
        name: item.name,
        email: item.email,
        role: item.role,
        phone: item.phone,
        photo: item.photo,
        user_data: item.user_data || {
          height: "",
          weight: "",
          country: "",
          city: "",
          gender: "",
          activity: "",
        },
      });
    }

    setEditModal((open) => !open);
    return editModal;
  };

  return (
    <>
      <div
        style={{
          paddingTop: "30px",
          padding: "0 2%",
          overflow: "hidden",
        }}
      >
        <Row
          style={{
            padding: "2% 0",
          }}
        >
          <div
            className="col"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card
              style={{
                border: "1px solid #E4E5E8",
              }}
              className="shadow"
            >
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">All Customers</h3>
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Image
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Name
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Height{" "}
                        <sub
                          style={{
                            fontWeight: "500",
                            color: "black",
                            fontSize: "10px",
                            textTransform: "lowercase",
                          }}
                        >
                          ft
                        </sub>
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Weight{" "}
                        <sub
                          style={{
                            fontWeight: "500",
                            color: "black",
                            fontSize: "10px",
                            textTransform: "lowercase",
                          }}
                        >
                          lb
                        </sub>
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Gender
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length === 0 ? (
                      <tr>
                        <td
                          colSpan="100%"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          Customers Not Found
                        </td>
                      </tr>
                    ) : (
                      users
                        ?.filter((item) => !item.deleted)
                        .map((item, index) => {
                          return (
                            <tr
                              style={{
                                color: "black",
                              }}
                            >
                              <th scope="row">{index + 1}</th>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media className="align-items-center">
                                    <Avatar
                                      src={
                                        /* eslint-disable @typescript-eslint/restrict-template-expressions */ item?.photo
                                          ? `${mediaUrl}/${item?.photo}`
                                          : ""
                                      }
                                      sx={{
                                        width: "40px",
                                        height: "40px",
                                      }}
                                    >
                                      {item.name.split("")[0].toUpperCase()}
                                    </Avatar>
                                  </Media>
                                </Media>
                              </th>
                              <td
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.name?.length > 30
                                  ? item.name?.substring(0, 30) + "..."
                                  : item.name}
                              </td>
                              <td title={item.email}>
                                {item.email?.length > 30
                                  ? item.email?.substring(0, 30) + "..."
                                  : item.email}
                              </td>
                              <td className=" text-capitalize">
                                {item?.user_data?.height}{" "}
                                <sub
                                  style={{
                                    fontWeight: "500",
                                    color: "black",
                                    fontSize: "10px",
                                    textTransform: "lowercase",
                                  }}
                                >
                                  ft
                                </sub>
                              </td>
                              <td className=" text-capitalize">
                                {item?.user_data?.weight}{" "}
                                <sub
                                  style={{
                                    fontWeight: "500",
                                    color: "black",
                                    fontSize: "10px",
                                    textTransform: "lowercase",
                                  }}
                                >
                                  lb
                                </sub>
                              </td>
                              <td className=" text-capitalize">
                                {item?.user_data?.gender}
                              </td>
                              <td
                                className="text-left"
                                style={{
                                  display: "flex",
                                  gap: "6px",
                                }}
                              >
                                <VisibilityIcon
                                  sx={{
                                    color: "#007BFF",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleModalView(item._id)}
                                />
                                <EditCalendarIcon
                                  sx={{
                                    color: "#007BFF",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleEditModal(item)}
                                />
                                <DeleteIcon
                                  sx={{
                                    color: "#007BFF",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDelete(item._id)}
                                />
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>
        {openModal && (
          <AddUser
            openModal={openModal}
            handleModal={handleModal}
            getUsers={getUsers}
          />
        )}
        {editModal && (
          <EditUser
            openModal={editModal}
            handleModal={handleEditModal}
            user={user}
            setUser={setUser}
            getUsers={getUsers}
          />
        )}
        {openModalView && (
          <ViewUser
            openModalView={openModalView}
            handleModalView={handleModalView}
            userId={selectedUserId}
          />
        )}
      </div>
    </>
  );
};

export default Users;
