// reactstrap components
import api from "../../api";
import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import PersonIcon from "@mui/icons-material/Person";
import LinearProgress from "@mui/material/LinearProgress";

const Header = () => {
  const [customeCount, setCustomeCount] = useState(0);
  const [coachCount, setCoachCount] = useState(0);
  const [storeCount, setStoreCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const Role = localStorage.getItem("role");
  const _id = localStorage.getItem("id");

  const getUsers = async () => {
    try {
      const data = await api("get", "/v1/users?role=customer");
      const data2 = await api("get", "/v1/users?role=coach");
      const data3 = await api("get", "/v1/stores");
      const data4 = await api("get", `/v1/products?store=${_id}`);
      const dat5 = await api("get", `/v1/orders?store=${_id}`);

      const filteredCustomers = data.users.filter((user) => !user.deleted);
      const filteredCoaches = data2.users.filter((user) => !user.deleted);
      const filteredStores = data3.stores.filter((stores) => !stores.deleted);
      const filteredProducts = data4.products.filter(
        (products) => !products.deleted
      );
      const filteredOrders = dat5.orders.filter((orders) => !orders.deleted);

      setCustomeCount(filteredCustomers.length);
      setCoachCount(filteredCoaches.length);
      setStoreCount(filteredStores.length);
      setProductCount(filteredProducts.length);
      setOrderCount(filteredOrders.length);

      setLoading(false);
    } catch (error) {
      //error
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <div className="header overflow-hidden">
        <div
          style={{
            width: "100%",
            marginTop: "30px",
            padding: "0 3% 0 2%",
          }}
        >
          <div className="header-body">
            {/* Card starts */}
            <h1>Dashboard</h1>
            {loading ? (
              <LinearProgress
                color="inherit"
                sx={{
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: "#F6FAFB",
                  },
                  "& .MuiLinearProgress-barColorSecondary": {
                    backgroundColor: "#F6FAFB",
                  },
                }}
              />
            ) : (
              <Row>
                <Col lg="6" xl="4" className="hover-col">
                  <Card
                    className="pt-3 pb-3 card-stats mb-4 mb-xl-0"
                    style={{ backgroundColor: "#17A2B8" }}
                  >
                    <CardBody className="pt-0 pb-0 ">
                      <Row className="bg-">
                        <div
                          className="col"
                          style={{
                            // backgroundColor: "aqua",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span
                            className="h2  mb-0 text-white bg-"
                            style={{
                              fontSize: "2.2rem",
                              fontWeight: "700",
                              padding: "0",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {Role !== "admin" ? productCount : customeCount}
                          </span>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 text-white"
                            style={{ fontSize: "1rem" }}
                          >
                            {Role !== "admin"
                              ? "Total Products"
                              : "Total Customer"}
                          </CardTitle>
                        </div>
                        <Col
                          className="col-auto"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // background: "aqua",
                            padding: "12px 2px",
                            transition: "transform 0.3s ease",
                            "&:hover .icon-wrapper": {
                              transform: "scale(1.1)",
                            },
                          }}
                        >
                          <div className="icon-wrapper  text-white rounded-circle shadow ">
                            <PersonIcon
                              sx={{
                                fontSize: "5rem",
                                color: "#006F82",
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4" className="hover-col">
                  <Card
                    className="pt-3 pb-3 card-stats mb-4 mb-xl-0"
                    style={{ backgroundColor: "#28A745" }}
                  >
                    <CardBody className="pt-0 pb-0 ">
                      <Row className="bg-">
                        <div
                          className="col"
                          style={{
                            // backgroundColor: "aqua",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span
                            className="h2  mb-0 text-white bg-"
                            style={{
                              fontSize: "2.2rem",
                              fontWeight: "700",
                              padding: "0",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {Role !== "admin" ? orderCount : coachCount}
                          </span>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0 text-white"
                            style={{ fontSize: "1rem" }}
                          >
                            {Role !== "admin"
                              ? "Total Orders"
                              : "Total Coaches"}
                          </CardTitle>
                        </div>
                        <Col
                          className="col-auto"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // background: "aqua",
                            padding: "12px 2px",
                          }}
                        >
                          <div className="icon-wrapper  text-white rounded-circle shadow ">
                            <PersonIcon
                              sx={{
                                fontSize: "5rem",
                                color: "green",
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {Role !== "admin" ? (
                  ""
                ) : (
                  <Col lg="6" xl="4" className="hover-col">
                    <Card
                      className="pt-3 pb-3 card-stats mb-4 mb-xl-0"
                      style={{ backgroundColor: "#DC3545" }}
                    >
                      <CardBody className="pt-0 pb-0 ">
                        <Row className="bg-">
                          <div
                            className="col"
                            style={{
                              // backgroundColor: "aqua",
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <span
                              className="h2  mb-0 text-white bg-"
                              style={{
                                fontSize: "2.2rem",
                                fontWeight: "700",
                                padding: "0",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {storeCount}
                            </span>
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 text-white"
                              style={{ fontSize: "1rem" }}
                            >
                              Total Stores
                            </CardTitle>
                          </div>
                          <Col
                            className="col-auto"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // background: "aqua",
                              padding: "12px 2px",
                            }}
                          >
                            <div className="icon-wrapper  text-white rounded-circle shadow ">
                              <PersonIcon
                                sx={{
                                  fontSize: "5rem",
                                  color: "#8C001C",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
