// reactstrap components
import { useState, useEffect } from "react";
import api from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card, CardHeader, Button, Table, Row } from "reactstrap";

// core components
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

import LinearProgress from "@mui/material/LinearProgress";
import ViewFAQ from "./ViewFAQ";
import AddFAQ from "./AddFAQ";
import EditFAQ from "./EditFAQ";

const Blogs = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [faq, setFaq] = useState();
  const [loading, setLoading] = useState(true);
  const [faqId, setFaqId] = useState(null);

  const [editModal, setEditModal] = useState(false);

  const [editFAQ, setEditFAQ] = useState({
    _id: "",
    question: "",
    answer: "",
  });

  const getUsers = async () => {
    const data = await api("get", "/v1/faqs");
    setFaq(data.faqs);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id) => {
    api("delete", `/v1/faqs/${id}`).then(() => {
      toast.success("FAQs deleted successfully");
      getUsers();
    });
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const handleModalView = (id) => {
    setOpenModalView((open) => !open);
    setFaqId(id);
    return openModalView;
  };

  const handleEditModal = (item) => {
    if (item) {
      setEditFAQ({
        _id: item._id,
        question: item.question,
        answer: item.answer,
      });
    }

    setEditModal((open) => !open);
    return editModal;
  };

  return (
    <>
      <div
        style={{
          paddingTop: "30px",
          padding: "0 2%",
        }}
      >
        <Row
          style={{
            padding: "2% 0",
          }}
        >
          <div
            className="col"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card
              style={{
                border: "1px solid #E4E5E8",
              }}
              className="shadow"
            >
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">All FAQs</h3>
                  <Button
                    style={{
                      backgroundColor: "#218838",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "none",
                    }}
                    color="primary"
                    size="md"
                    onClick={handleModal}
                  >
                    Create FAQs
                  </Button>
                </div>
              </CardHeader>
              {loading ? (
                <LinearProgress
                  color="inherit"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#F6FAFB",
                    },
                    "& .MuiLinearProgress-barColorSecondary": {
                      backgroundColor: "#F6FAFB",
                    },
                  }}
                />
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F2F2F2",
                      position: "sticky",
                      top: "0",
                      zIndex: 19,
                    }}
                  >
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Question
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Answer
                      </th>
                      <th
                        scope="col "
                        style={{
                          fontWeight: "700",
                          color: "black",
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {faq.map((item, index) => {
                      return (
                        <tr
                          style={{
                            color: "black",
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {item.question?.length > 30
                              ? item.question?.substring(0, 30) + "..."
                              : item.question}
                          </td>
                          <td>
                            {item.answer?.length > 30
                              ? item.answer?.substring(0, 30) + "..."
                              : item.answer}
                          </td>
                          <td
                            className="text-left"
                            style={{
                              display: "flex",
                              gap: "6px",
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                color: "#007BFF",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleModalView(item._id)}
                            />
                            <EditCalendarIcon
                              sx={{
                                color: "#007BFF",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEditModal(item)}
                            />
                            <DeleteIcon
                              sx={{
                                color: "#007BFF",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(item._id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>

        {openModal && (
          <AddFAQ
            openModal={openModal}
            handleModal={handleModal}
            getUsers={getUsers}
          />
        )}
        {editModal && (
          <EditFAQ
            openModal={editModal}
            handleModal={handleEditModal}
            editFAQ={editFAQ}
            setEditFAQ={setEditFAQ}
            getUsers={getUsers}
          />
        )}
        {openModalView && (
          <ViewFAQ
            openModalView={openModalView}
            handleModalView={handleModalView}
            faqId={faqId}
          />
        )}
      </div>
    </>
  );
};

export default Blogs;
