import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import api from "api";
import { useEffect } from "react";

const ViewFAQ = ({ openModalView, handleModalView, faqId }) => {
  const [faq, setFaq] = useState();
  const getFAQs = async () => {
    const data = await api("get", `/v1/faqs/${faqId}`);
    setFaq(data.faq);
  };

  useEffect(() => {
    getFAQs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal isOpen={openModalView} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModalView}>
          FAQs
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="10" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Question</label>
                        <Input
                          className="form-control-alternative text-default"
                          type="text"
                          value={faq?.question}
                          name="question"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="10" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-role"
                        >
                          Answer
                        </label>
                        <textarea
                          style={{
                            width: "100%",
                            padding: "1%",
                            resize: "none" /* Disable resizing */,
                          }}
                          className="form-control-alternative text-default"
                          value={faq?.answer}
                          name="answer"
                          disabled
                          rows={10}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalView}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewFAQ;
